import React from "react";
import { useSelector } from 'react-redux';
import { selectMilestones, selectSandboxMode } from '../../redux/gameSlice';

const ScareCrow = () => {
    let isSandbox = useSelector(selectSandboxMode);
    let isScarecrowUnlocked = useSelector(selectMilestones).speed;
    let isBarnUnlocked = useSelector(selectMilestones).wood;

    return (
        <div className={`z-10 absolute bottom-0 left-24 focus:outline-none no-select transition duration-2000`}>
        {(isSandbox || isScarecrowUnlocked) && !isBarnUnlocked ?
            <svg height="50" viewBox="0 0 83 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M49.1785 26.8824H38.6453V100H49.1785V26.8824Z" fill="#A38948"/>
                <path d="M13.3051 39.6029L0 43.9069L4.72735 46.6889L0.755972 53.4624L6.26953 51.5069L5.93692 55.8412L19.0404 46.2857L13.3051 39.6029Z" fill="#D3D329"/>
                <path d="M63.3403 52.0512L74.8311 63.4412L73.9239 55.8512L77.2906 58.2199L76.7261 53.0591L82.3909 51.769L75.3654 49.6119L68.3298 42.6771L63.3403 52.0512Z" fill="#D3D329"/>
                <path d="M26.6505 59.0565L22.9816 71.6661L30.4606 70.0836L36.206 76.464L41.4978 70.7086L55.7 72.9362L58.8953 70.7086L60.4274 71.3436L59.5303 62.403L26.6505 59.0565Z" fill="#D3D329"/>
                <path d="M23.3445 64.8826L62.9372 67.7553L55.1155 47.6464L67.5638 58.1796L74.5893 45.4087L46.9711 26.8925H36.4379L10.4224 39.0283L15.5227 50.3578L31.8113 45.0963L23.3445 64.8826Z" fill="#A0754E"/>
                <path d="M51.9807 100L55.6295 91.1803L45.0963 96.1899L42.5058 90.505L37.9599 95.2525L32.0129 92.9644L35.7525 100H51.9807Z" fill="#498E1C"/>
                <path d="M43.1408 32.9705C50.8119 32.9705 57.0306 26.7519 57.0306 19.0808C57.0306 11.4097 50.8119 5.19104 43.1408 5.19104C35.4697 5.19104 29.2511 11.4097 29.2511 19.0808C29.2511 26.7519 35.4697 32.9705 43.1408 32.9705Z" fill="#E89F1F"/>
                <path d="M50.4788 18.244C52.2936 18.244 53.7648 16.7729 53.7648 14.9581C53.7648 13.1433 52.2936 11.6721 50.4788 11.6721C48.6641 11.6721 47.1929 13.1433 47.1929 14.9581C47.1929 16.7729 48.6641 18.244 50.4788 18.244Z" fill="#A0754E"/>
                <path d="M34.422 23.6166C36.2367 23.6166 37.7079 22.1454 37.7079 20.3306C37.7079 18.5159 36.2367 17.0447 34.422 17.0447C32.6072 17.0447 31.136 18.5159 31.136 20.3306C31.136 22.1454 32.6072 23.6166 34.422 23.6166Z" fill="#A0754E"/>
                <path d="M19.4639 26.4792L63.9453 6.68279L52.7972 5.35228L49.481 0L26.2676 11.0977L26.7414 17.4882L19.4639 26.4792Z" fill="#A38948"/>
                <path d="M37.0426 24.4431C37.0426 24.4431 41.276 27.719 46.1143 26.4591C47.5175 26.0724 48.8002 25.3375 49.8434 24.3224C50.8866 23.3074 51.6565 22.0453 52.0815 20.6532C52.0815 20.6532 53.9562 27.9711 47.2331 29.9567C40.51 31.9424 37.0426 24.4431 37.0426 24.4431Z" fill="#A0754E"/>
            </svg>: null}
            {(isScarecrowUnlocked && isBarnUnlocked) ? 

                <svg width="200" className="-mb-1 -ml-2" viewBox="0 0 389 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 96.1399C38.76 69.8299 111.75 59.3999 208.87 59.3999C319.16 59.3999 388.93 96.1399 388.93 96.1399H0Z" fill="#1DAA4F"/>
                    <path d="M225.3 18.7999H218.41V66.6199H225.3V18.7999Z" fill="#A38948"/>
                    <path d="M201.84 27.12L193.13 29.94L196.23 31.75L193.63 36.18L197.24 34.91L197.02 37.74L205.59 31.49L201.84 27.12Z" fill="#D3D329"/>
                    <path d="M234.56 35.2599L242.08 42.7099L241.48 37.7499L243.68 39.2899L243.31 35.9199L247.02 35.0699L242.43 33.6599L237.83 29.1299L234.56 35.2599Z" fill="#D3D329"/>
                    <path d="M210.57 39.84L208.17 48.09L213.06 47.05L216.82 51.23L220.28 47.46L229.57 48.92L231.65 47.46L232.66 47.87L232.07 42.03L210.57 39.84Z" fill="#D3D329"/>
                    <path d="M208.41 43.6499L234.29 45.5299L229.18 32.3799L237.32 39.2699L241.91 30.9199L223.86 18.7999H216.97L199.95 26.7399L203.29 34.1499L213.94 30.7099L208.41 43.6499Z" fill="#A0754E"/>
                    <path d="M227.13 66.6199L229.52 60.8499L222.63 64.1299L220.93 60.4099L217.96 63.5099L214.07 62.0199L216.52 66.6199H227.13Z" fill="#498E1C"/>
                    <path d="M221.35 22.77C226.365 22.77 230.43 18.7047 230.43 13.69C230.43 8.67524 226.365 4.60999 221.35 4.60999C216.335 4.60999 212.27 8.67524 212.27 13.69C212.27 18.7047 216.335 22.77 221.35 22.77Z" fill="#E89F1F"/>
                    <path d="M226.15 13.15C227.337 13.15 228.3 12.1874 228.3 11C228.3 9.81256 227.337 8.84998 226.15 8.84998C224.963 8.84998 224 9.81256 224 11C224 12.1874 224.963 13.15 226.15 13.15Z" fill="#A0754E"/>
                    <path d="M215.65 16.67C216.837 16.67 217.8 15.7074 217.8 14.52C217.8 13.3326 216.837 12.37 215.65 12.37C214.463 12.37 213.5 13.3326 213.5 14.52C213.5 15.7074 214.463 16.67 215.65 16.67Z" fill="#A0754E"/>
                    <path d="M206.39 17.5099L235.48 4.55994L228.19 3.68994L226.02 0.189941L210.84 7.44994L211.15 11.6299L206.39 17.5099Z" fill="#A38948"/>
                    <path d="M217.38 17.97C218.301 18.443 219.31 18.7184 220.343 18.7787C221.376 18.839 222.411 18.6827 223.38 18.3199C224.248 17.9296 225.003 17.326 225.574 16.5654C226.146 15.8049 226.516 14.912 226.65 13.97C226.65 13.97 228.65 18.5 224.47 20.47C220.29 22.44 217.38 17.97 217.38 17.97Z" fill="#A0754E"/>
                </svg>

            : null}
        </div> 
    )
}

export default ScareCrow;