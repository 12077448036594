import React from 'react';
import { useSelector } from 'react-redux';
import { selectSandboxMode } from '../../redux/gameSlice';

const AcornIcon = ({amount, color}) => {

    let isSandbox = useSelector(selectSandboxMode);

    return (
        <div className={`flex w-max items-start ${amount === 0 && !isSandbox ? 'opacity-50' : 'opacity-100'}`}>
            <svg width="22" height="21" viewBox="0 0 24 24" className={`${color} fill-current`} xmlns="http://www.w3.org/2000/svg">
                <path d="M4.53662 18.2047C10.8348 15.2841 16.8138 10.5154 20.4126 5.98596C20.9286 6.70242 22.9897 8.63844 23.8473 13.596C24.3424 16.4589 23.5967 19.1028 22.1486 21.5995C21.983 21.885 21.892 22.2569 21.9025 22.5841C21.9338 23.5053 21.4715 23.8498 20.5468 23.6105C20.1963 23.5197 19.7772 23.5284 19.4253 23.6192C17.4596 24.1208 15.488 24.105 13.5178 23.6999C10.0294 22.9834 7.06156 21.3876 4.73647 18.7424C4.51425 18.4872 4.75287 18.4872 4.53662 18.2047Z" />
                <path d="M3.51051 3.42429C2.68577 2.67468 2.77376 2.49737 1.58661 2.55215C1.33158 2.56368 1.11533 2.36907 1.09743 2.12256C1.07655 1.82127 1.17498 1.02409 1.26148 0.731451C1.47326 0.0178771 1.96691 -0.074383 2.25774 0.0423837C3.59851 0.578646 4.12795 0.708387 5.04069 1.70162C5.49109 2.19176 5.57312 2.27248 6.15924 1.94669C9.59244 0.0409421 13.1718 -0.367021 16.8078 1.33547C17.7966 1.79821 18.4901 2.26239 19.3029 3.00912C19.8592 3.52232 19.8488 4.41032 19.6087 4.76783C19.1076 5.51312 18.7332 5.79278 18.1218 6.44581C14.3261 10.5024 9.93695 13.8454 4.94375 16.4272C4.22937 16.7962 3.66562 17.184 2.89456 17.4464C2.41583 17.6093 1.84611 17.2619 1.57617 16.8308C-0.699708 13.2082 -0.399936 9.61149 1.76259 6.08686C2.30844 5.19742 2.85728 4.81973 3.54332 4.04417C3.70589 3.86253 3.69097 3.58863 3.51051 3.42429Z" />
            </svg>
            {isSandbox ? 
                <svg width="26" height="14" viewBox="0 0 26 14" className="fill-current text-white my-auto ml-1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.1358 0C15.8882 0 13.3774 2.28539 12.4561 5.39365C12.3253 5.76812 12.202 6.16186 12.0904 6.57169C11.9832 6.94937 11.9081 7.18563 11.8008 7.626C11.3064 9.62209 9.3941 11.1071 6.86724 11.1071C4.0433 11.1071 1.93366 8.93905 1.93366 7.00081C1.93366 5.06257 4.0433 2.89289 6.86724 2.89289C8.41596 2.89289 9.75017 3.5454 10.6393 4.45505C10.6832 4.49914 10.7331 4.52809 10.7853 4.5398C10.8376 4.55152 10.8909 4.54572 10.9415 4.52281C10.9921 4.49991 11.0386 4.46046 11.0778 4.40734C11.117 4.35421 11.1478 4.28872 11.168 4.21558C11.3075 3.71576 11.507 3.03271 11.6796 2.54735C11.7187 2.43952 11.7293 2.31369 11.7095 2.19466C11.6896 2.07564 11.6407 1.97209 11.5724 1.90448C10.3443 0.724826 8.6916 0.00160873 6.87153 0.00160873C3.08018 0.00160873 0.00741577 3.13558 0.00741577 7.00242C0.00741577 10.8692 3.08018 14.0016 6.87153 14.0016C9.95502 14.0016 12.5634 11.9284 13.43 9.07244C13.5149 8.81973 13.5866 8.55751 13.6445 8.28815C14.2805 5.33579 15.5943 4.15131 16.4223 3.62738C17.29 3.12969 18.2121 2.87895 19.1411 2.88807C21.9651 2.88807 24.0747 5.05614 24.0747 6.99598C24.0747 8.93583 21.9651 11.1023 19.1411 11.1023C17.601 11.1023 16.2732 10.4594 15.3873 9.5562C15.3406 9.50976 15.2878 9.47884 15.2325 9.46554C15.1771 9.45225 15.1205 9.45687 15.0664 9.4791C15.0124 9.50133 14.9621 9.54067 14.9191 9.59444C14.876 9.64821 14.8411 9.71518 14.8167 9.79084C14.6645 10.2569 14.4639 10.8596 14.3041 11.2919C14.2544 11.4238 14.24 11.5791 14.2639 11.7262C14.2878 11.8734 14.3482 12.0014 14.4328 12.0843C15.663 13.2687 17.32 13.9952 19.1444 13.9952C22.9357 13.9952 26.0085 10.8612 26.0085 6.99598C26.0085 3.13076 22.9271 0 19.1358 0Z" />
                </svg>
            :
                <div className={`ml-1 ${color} text-md font-medium no-select`}>{`: ${amount}`}</div>
            }
        </div>
    )
}

export default AcornIcon;
