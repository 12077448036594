import React from 'react';
import { useSelector } from 'react-redux';
import { selectSandboxMode } from '../../redux/gameSlice';

const WoodIcon = ({amount, color}) => {

    let isSandbox = useSelector(selectSandboxMode);

    return (
        <div className={`flex w-max items-start ${amount === 0 && !isSandbox ? 'opacity-50' : 'opacity-100'}`}>
            <svg width="20" height="22" className={`${color} fill-current`} viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.0264 6.71974C16.9746 6.56327 15.6101 6.02749 14.2835 5.03536C12.8621 3.97685 11.7226 2.63622 11.2038 1.6678C10.8165 0.948293 10.826 0.794197 10.4126 1.34064C9.66694 2.935 8.80963 4.47465 7.84708 5.94808C7.40645 4.55885 7.02506 3.00368 7.02506 3.00368C6.90661 2.72868 6.78816 2.8638 6.58799 3.1068C6.2008 3.54904 5.71343 3.89208 5.16663 4.10723C4.57032 4.3637 3.92881 4.4983 3.27978 4.50314C2.9351 4.4901 2.88062 4.45336 2.98959 4.72125C3.67931 5.73711 4.2519 6.82786 4.6964 7.97265C4.8717 8.43256 5.06832 8.96952 5.2531 9.48278C3.05592 12.2091 0.171747 15.6169 0.171747 15.6169C0.0707359 15.7495 0.0115868 15.9092 0.00187932 16.0756C-0.00782814 16.242 0.0323495 16.4076 0.117262 16.551C0.666854 17.4827 1.65707 19.1528 3.21464 20.2504C4.7272 21.3173 5.49828 21.7298 6.46244 21.9739C6.62667 22.0157 6.79968 22.0066 6.95869 21.948C7.11769 21.8894 7.25522 21.784 7.35316 21.6456C7.35316 21.6456 9.84763 17.4661 12.2249 14.5157C14.6021 11.5654 18.7667 7.48665 18.7667 7.48665C19.1812 6.90702 18.7595 6.82997 18.0264 6.71974Z" />
                <path d="M16.5635 1.86337C14.2917 0.322419 12.0934 -0.412491 11.6516 0.237078C11.2098 0.886647 12.4736 3.08191 14.7442 4.62285C17.0148 6.1638 19.4323 6.4874 19.8741 5.83902C20.3159 5.19064 18.8342 3.4138 16.5635 1.86337Z" />
                <path d="M4.9404 3.65797C5.99102 3.16368 6.62826 2.35409 6.48731 2.05183C6.34636 1.74957 5.37984 1.90722 4.32803 2.40032C3.27623 2.89343 2.53949 3.53825 2.68044 3.83815C2.82139 4.13804 3.8886 4.15108 4.9404 3.65797Z" />
            </svg>
            {isSandbox ? 
                <svg width="26" height="14" viewBox="0 0 26 14" className="fill-current text-white my-auto ml-1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.1358 0C15.8882 0 13.3774 2.28539 12.4561 5.39365C12.3253 5.76812 12.202 6.16186 12.0904 6.57169C11.9832 6.94937 11.9081 7.18563 11.8008 7.626C11.3064 9.62209 9.3941 11.1071 6.86724 11.1071C4.0433 11.1071 1.93366 8.93905 1.93366 7.00081C1.93366 5.06257 4.0433 2.89289 6.86724 2.89289C8.41596 2.89289 9.75017 3.5454 10.6393 4.45505C10.6832 4.49914 10.7331 4.52809 10.7853 4.5398C10.8376 4.55152 10.8909 4.54572 10.9415 4.52281C10.9921 4.49991 11.0386 4.46046 11.0778 4.40734C11.117 4.35421 11.1478 4.28872 11.168 4.21558C11.3075 3.71576 11.507 3.03271 11.6796 2.54735C11.7187 2.43952 11.7293 2.31369 11.7095 2.19466C11.6896 2.07564 11.6407 1.97209 11.5724 1.90448C10.3443 0.724826 8.6916 0.00160873 6.87153 0.00160873C3.08018 0.00160873 0.00741577 3.13558 0.00741577 7.00242C0.00741577 10.8692 3.08018 14.0016 6.87153 14.0016C9.95502 14.0016 12.5634 11.9284 13.43 9.07244C13.5149 8.81973 13.5866 8.55751 13.6445 8.28815C14.2805 5.33579 15.5943 4.15131 16.4223 3.62738C17.29 3.12969 18.2121 2.87895 19.1411 2.88807C21.9651 2.88807 24.0747 5.05614 24.0747 6.99598C24.0747 8.93583 21.9651 11.1023 19.1411 11.1023C17.601 11.1023 16.2732 10.4594 15.3873 9.5562C15.3406 9.50976 15.2878 9.47884 15.2325 9.46554C15.1771 9.45225 15.1205 9.45687 15.0664 9.4791C15.0124 9.50133 14.9621 9.54067 14.9191 9.59444C14.876 9.64821 14.8411 9.71518 14.8167 9.79084C14.6645 10.2569 14.4639 10.8596 14.3041 11.2919C14.2544 11.4238 14.24 11.5791 14.2639 11.7262C14.2878 11.8734 14.3482 12.0014 14.4328 12.0843C15.663 13.2687 17.32 13.9952 19.1444 13.9952C22.9357 13.9952 26.0085 10.8612 26.0085 6.99598C26.0085 3.13076 22.9271 0 19.1358 0Z" />
                </svg>
            :
                <div className={`ml-1 ${color} text-md font-medium no-select`}>{`: ${amount}`}</div>
            }
        </div>
    )
}

export default WoodIcon;
