import React from 'react';

const GraveStone = ({ data }) => {

  let left = `${data.x}%`;
  let top = `${data.y - 1}%`;

  return (  
    <div 
      className="absolute"
      style={{
          left: left, 
          top: top, 
          width: "18px",
          height: "30px"
          }} >
      <svg width="18" height="30" viewBox="0 0 18 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.2356 0H7.89485C5.80345 0 3.79771 0.844027 2.31887 2.34643C0.840022 3.84883 0.00921631 5.88653 0.00921631 8.01124V29.9916H10.1153L10.2356 0Z" fill="#A8A8A8"/>
      <path d="M18 8.0197C18 5.89498 17.1692 3.85728 15.6904 2.35488C14.2115 0.85248 12.2058 0.00845337 10.1144 0.00845337C8.02296 0.00845337 6.01722 0.85248 4.53838 2.35488C3.05954 3.85728 2.22873 5.89498 2.22873 8.0197V30H18V8.0197Z" fill="#878787"/>
      <path d="M15.7837 8.4329C15.7837 6.89416 15.182 5.41844 14.1111 4.33039C13.0401 3.24234 11.5875 2.63107 10.0729 2.63107C8.55825 2.63107 7.10567 3.24234 6.03467 4.33039C4.96368 5.41844 4.362 6.89416 4.362 8.4329V30H15.7837V8.4329Z" fill="#A8A8A8"/>
      <path d="M13.9368 10.0099H6.27944C5.88519 10.0099 5.56558 10.3346 5.56558 10.7351V10.7393C5.56558 11.1398 5.88519 11.4645 6.27944 11.4645H13.9368C14.3311 11.4645 14.6507 11.1398 14.6507 10.7393V10.7351C14.6507 10.3346 14.3311 10.0099 13.9368 10.0099Z" fill="#878787"/>
      <path d="M13.9036 15.3099H6.24627C5.85201 15.3099 5.53241 15.6346 5.53241 16.0351V16.0394C5.53241 16.4399 5.85201 16.7646 6.24627 16.7646H13.9036C14.2979 16.7646 14.6175 16.4399 14.6175 16.0394V16.0351C14.6175 15.6346 14.2979 15.3099 13.9036 15.3099Z" fill="#878787"/>
      <path d="M13.9368 17.5783H6.27944C5.88519 17.5783 5.56558 17.903 5.56558 18.3036V18.3078C5.56558 18.7083 5.88519 19.033 6.27944 19.033H13.9368C14.3311 19.033 14.6507 18.7083 14.6507 18.3078V18.3036C14.6507 17.903 14.3311 17.5783 13.9368 17.5783Z" fill="#878787"/>
      <path d="M9.24693 12.5987H6.09683C5.80343 12.5987 5.56558 12.8404 5.56558 13.1384V13.5053C5.56558 13.8033 5.80343 14.045 6.09683 14.045H9.24693C9.54033 14.045 9.77817 13.8033 9.77817 13.5053V13.1384C9.77817 12.8404 9.54033 12.5987 9.24693 12.5987Z" fill="#878787"/>
      <path d="M14.1195 12.5987H10.9694C10.676 12.5987 10.4381 12.8404 10.4381 13.1384V13.5053C10.4381 13.8033 10.676 14.045 10.9694 14.045H14.1195C14.4129 14.045 14.6507 13.8033 14.6507 13.5053V13.1384C14.6507 12.8404 14.4129 12.5987 14.1195 12.5987Z" fill="#878787"/>
    </svg>
    </div>
  )
}

export default GraveStone;