import React from 'react';
import { useSelector } from 'react-redux';
import { selectMilestones } from '../../redux/gameSlice';

const Altar = () => {

  let isAltarUnlocked = useSelector(selectMilestones).altar

  return (
    <div className={`z-10 absolute bottom-0 w-full focus:outline-none no-select transition duration-2000`}>
      <div className="w-min mx-auto">
      {isAltarUnlocked ? <svg height="75" viewBox="0 0 137 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M136.541 100H0L20.8203 60.4259H115.318L136.541 100Z" fill="#3A4F3A"/>
            <path d="M101.089 60.4259H85.994L84.9135 49.3326H87.6204V45.6538H49.3183V49.3326H51.8251L51.0562 60.4259H37.4332L23.4072 100H115.387L101.089 60.4259Z" fill="#596B59"/>
            <path d="M94.4919 60.4287C96.2555 38.991 87.8434 18.5108 87.8434 18.5108V16.4756L89.2011 14.4405C89.2011 14.4405 97.2045 25.428 99.6455 36.1469C102.087 46.8658 104.665 60.4287 104.665 60.4287H94.4919Z" fill="#A0A07C"/>
            <path d="M88.9495 21.5178C91.2362 25.5767 93.7573 30.8904 94.9521 36.1469C97.3932 46.863 99.9714 60.4288 99.9714 60.4288H94.4919C95.8639 43.9102 91.1705 27.9663 88.9495 21.5178Z" fill="#666550"/>
            <path d="M100.923 60.4287C103.239 32.2652 92.1881 5.348 92.1881 5.348V2.67539L93.9717 0C93.9717 0 104.488 14.4405 107.692 28.5208C110.896 42.6011 114.289 60.4287 114.289 60.4287H100.923Z" fill="#A0A07C"/>
            <path d="M93.6401 9.30115C96.6271 14.632 99.9571 21.615 101.529 28.5208C104.739 42.604 108.126 60.4287 108.126 60.4287H100.923C102.707 38.7251 96.5585 17.7791 93.6401 9.30115Z" fill="#666550"/>
            <path d="M42.6726 60.4287C40.909 38.991 49.3183 18.5108 49.3183 18.5108V16.4756L47.9606 14.4405C47.9606 14.4405 39.9571 25.428 37.5161 36.1469C35.075 46.8658 32.4968 60.4287 32.4968 60.4287H42.6726Z" fill="#A0A07C"/>
            <path d="M48.2121 21.5178C45.9254 25.5767 43.4043 30.8904 42.2095 36.1469C39.7656 46.863 37.1902 60.4288 37.1902 60.4288H42.6697C41.312 43.9102 45.994 27.9663 48.2121 21.5178Z" fill="#666550"/>
            <path d="M36.2412 60.4287C33.9231 32.2652 44.9736 5.348 44.9736 5.348V2.67539L43.1928 0C43.1928 0 32.674 14.4405 29.4726 28.5208C26.2713 42.6011 22.8784 60.4287 22.8784 60.4287H36.2412Z" fill="#A0A07C"/>
            <path d="M43.5215 9.30115C40.5345 14.632 37.2045 21.615 35.6324 28.5208C32.4225 42.604 29.0382 60.4287 29.0382 60.4287H36.2412C34.4548 38.7251 40.606 17.7791 43.5215 9.30115Z" fill="#666550"/>
            <path d="M19.2597 82.2267L22.8955 75.4294C23.0773 75.0897 23.168 74.7086 23.1586 74.3234C23.1492 73.9382 23.0402 73.562 22.8421 73.2314C22.644 72.9009 22.3637 72.6274 22.0284 72.4375C21.6931 72.2476 21.3143 72.1479 20.929 72.148H12.1138C11.6661 72.1479 11.2287 72.2825 10.8586 72.5343C10.4885 72.7861 10.2027 73.1436 10.0386 73.5601L7.85766 79.091C7.73603 79.3992 7.68461 79.7306 7.70715 80.0612C7.7297 80.3917 7.82563 80.7131 7.98797 81.0019C8.15031 81.2908 8.37498 81.5397 8.64565 81.7308C8.91631 81.9219 9.22616 82.0503 9.55267 82.1066L16.8987 83.3729C17.3628 83.4556 17.8413 83.3893 18.2654 83.1834C18.6895 82.9775 19.0376 82.6425 19.2597 82.2267V82.2267Z" fill="#596B59"/>
            <path d="M5.90538 85.711L2.26668 92.5082C2.08488 92.8479 1.99424 93.2291 2.0036 93.6143C2.01296 93.9995 2.122 94.3757 2.32008 94.7062C2.51816 95.0367 2.79852 95.3103 3.13382 95.5001C3.46912 95.69 3.84791 95.7897 4.23324 95.7896H13.0342C13.4814 95.7896 13.9183 95.6549 14.288 95.4031C14.6576 95.1512 14.9428 94.7939 15.1065 94.3776L17.2874 88.8466C17.409 88.5386 17.4605 88.2075 17.4382 87.8771C17.4158 87.5467 17.3201 87.2255 17.1581 86.9367C16.996 86.6479 16.7717 86.3988 16.5014 86.2076C16.231 86.0163 15.9215 85.8878 15.5952 85.8311L8.24924 84.5648C7.78765 84.485 7.31266 84.5529 6.89185 84.7586C6.47103 84.9644 6.12584 85.2977 5.90538 85.711V85.711Z" fill="#596B59"/>
            <path d="M119.303 81.958L115.667 75.1608C115.485 74.821 115.394 74.44 115.404 74.0548C115.413 73.6695 115.522 73.2933 115.72 72.9628C115.918 72.6322 116.199 72.3587 116.534 72.1688C116.869 71.9789 117.248 71.8792 117.633 71.8794H126.42C126.867 71.8794 127.304 72.014 127.674 72.2659C128.043 72.5178 128.329 72.8751 128.492 73.2914L130.673 78.8224C130.795 79.1305 130.846 79.4619 130.824 79.7925C130.801 80.1231 130.705 80.4444 130.543 80.7333C130.38 81.0221 130.156 81.2711 129.885 81.4622C129.614 81.6533 129.305 81.7817 128.978 81.838L121.635 83.1042C121.175 83.1801 120.703 83.1104 120.285 82.9049C119.867 82.6994 119.523 82.3683 119.303 81.958V81.958Z" fill="#596B59"/>
            <path d="M132.64 85.4423L136.276 92.2395C136.459 92.5795 136.55 92.9611 136.542 93.3471C136.533 93.7332 136.424 94.1104 136.226 94.4418C136.028 94.7731 135.747 95.0474 135.411 95.2375C135.075 95.4277 134.695 95.5273 134.309 95.5266H125.508C125.061 95.5266 124.624 95.392 124.254 95.1401C123.885 94.8883 123.599 94.5309 123.436 94.1146L121.255 88.5837C121.133 88.2755 121.082 87.9441 121.104 87.6135C121.127 87.283 121.223 86.9616 121.385 86.6728C121.547 86.3839 121.772 86.1349 122.043 85.9439C122.313 85.7528 122.623 85.6244 122.95 85.5681L130.293 84.3018C130.754 84.2214 131.229 84.2883 131.651 84.493C132.072 84.6977 132.418 85.0298 132.64 85.4423V85.4423Z" fill="#596B59"/>
            <path d="M17.336 59.5741L13.6973 66.3713C13.5155 66.711 13.4249 67.0921 13.4342 67.4773C13.4436 67.8625 13.5526 68.2387 13.7507 68.5693C13.9488 68.8998 14.2291 69.1733 14.5644 69.3632C14.8997 69.5531 15.2785 69.6528 15.6639 69.6527H24.4648C24.9121 69.6527 25.349 69.518 25.7186 69.2661C26.0882 69.0143 26.3735 68.6569 26.5371 68.2406L28.718 62.7097C28.8397 62.4015 28.8911 62.0701 28.8685 61.7395C28.846 61.409 28.7501 61.0876 28.5877 60.7988C28.4254 60.5099 28.2007 60.261 27.93 60.0699C27.6594 59.8788 27.3495 59.7504 27.023 59.6941L19.6799 58.4278C19.2183 58.3485 18.7435 58.4166 18.3228 58.6223C17.902 58.8281 17.5568 59.161 17.336 59.5741V59.5741Z" fill="#596B59"/>
            <path d="M121.209 59.3054L124.845 66.1026C125.027 66.4424 125.117 66.8234 125.108 67.2087C125.099 67.5939 124.99 67.9701 124.792 68.3006C124.593 68.6312 124.313 68.9047 123.978 69.0946C123.642 69.2845 123.264 69.3842 122.878 69.384H114.077C113.63 69.384 113.193 69.2494 112.824 68.9975C112.454 68.7456 112.169 68.3883 112.005 67.972L109.824 62.4411C109.703 62.1329 109.651 61.8015 109.674 61.4709C109.696 61.1404 109.792 60.819 109.955 60.5302C110.117 60.2413 110.342 59.9923 110.612 59.8013C110.883 59.6102 111.193 59.4818 111.519 59.4255L118.865 58.1592C119.327 58.0803 119.801 58.1486 120.222 58.3543C120.643 58.56 120.988 58.8927 121.209 59.3054Z" fill="#596B59"/>
            <path d="M76.5928 43.5815C76.73 42.1523 75.4037 40.3715 73.1313 40.3315C70.8589 40.2915 69.8099 41.5892 69.8099 43.6587C69.8099 45.2594 71.0104 46.497 72.3682 46.2941L74.6177 45.9597C75.661 45.7911 76.4756 44.8192 76.5928 43.5815Z" fill="#D3BE84"/>
            <path d="M84.5991 45.7282L75.2551 40.9175L74.9207 45.7282H84.5991Z" fill="#E22D29"/>
            <path d="M67.266 35.5695H66.1083C66.0957 35.5695 66.0855 35.5798 66.0855 35.5924V43.3014C66.0855 43.314 66.0957 43.3243 66.1083 43.3243H67.266C67.2786 43.3243 67.2888 43.314 67.2888 43.3014V35.5924C67.2888 35.5798 67.2786 35.5695 67.266 35.5695Z" fill="#AFAFAF"/>
            <path d="M56.8101 61.8522L55.7925 68.3522H83.4358L82.0838 61.8522H56.8101Z" fill="#3A4F3A"/>
            <path d="M53.3114 69.6841L51.9994 76.1812H87.6204L85.8768 69.6841H53.3114Z" fill="#3A4F3A"/>
            <path d="M48.0063 77.5161L46.3313 84.0132H91.8251L89.5984 77.5161H48.0063Z" fill="#3A4F3A"/>
            <path d="M42.2438 85.3452L40.0886 91.8451H98.7052L95.8411 85.3452H42.2438Z" fill="#3A4F3A"/>
            <path d="M34.7034 92.9141L33.0227 100L104.122 99.9943L101.481 93.1657L34.7034 92.9141Z" fill="#3A4F3A"/>
            <path d="M68.2236 38.1135V37.1445H65.1937V38.1135H68.2236Z" fill="#604E42"/>
            <path d="M70.7103 41.5578V45.8796L53.9603 45.6567C53.1571 45.2508 55.5409 41.3948 58.9881 41.5578C60.4831 41.6264 61.8236 39.6655 63.6787 40.0657C65.5338 40.4659 65.6967 40.5345 66.5142 41.069C67.3317 41.6035 70.2787 40.9089 70.7103 41.5578Z" fill="#604E42"/>
            <path d="M51.9994 51.5364H85.1279L84.9135 49.3326H51.8251L51.6736 51.5364H51.9994Z" fill="#3A4F3A"/>
        </svg> : null }
      </div>
    </div>)
}

export default Altar;