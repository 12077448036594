import React from 'react';

const Mural = () => {
  return (
    <svg className="w-full" viewBox="0 0 1800 360" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1800 0H0V183.06H1800V0Z" fill="#7DD3FC"/>
  <path d="M663.22 346.34C748.454 346.34 817.55 277.423 817.55 192.41C817.55 107.397 748.454 38.48 663.22 38.48C577.986 38.48 508.89 107.397 508.89 192.41C508.89 277.423 577.986 346.34 663.22 346.34Z" fill="#FFFF80"/>
  <path d="M1800 179.53H0V359.06H1800V179.53Z" fill="#28C665"/>
  <path d="M253.2 183.08C281.49 143.36 334.75 127.61 405.63 127.61C486.11 127.61 537.03 183.08 537.03 183.08H253.2Z" fill="#68A81E"/>
  <path d="M1343.38 183.08C1376.64 143.94 1439.26 128.43 1522.59 128.43C1617.22 128.43 1677.09 183.08 1677.09 183.08H1343.38Z" fill="#68A81E"/>
  <path d="M1156.32 182.59C1190.4 134.74 1254.55 115.77 1339.91 115.77C1436.86 115.77 1498.18 182.59 1498.18 182.59H1156.32Z" fill="#D6CD21"/>
  <path d="M1018.32 182.59C1037.76 155.29 1074.32 144.48 1123.05 144.48C1178.36 144.48 1213.34 182.59 1213.34 182.59H1018.32Z" fill="#68A81E"/>
  <path d="M698.32 180.59C727.96 154.59 773.75 143.99 803.05 142.47C858.28 139.62 893.34 180.59 893.34 180.59H698.32Z" fill="#68A81E"/>
  <path d="M800.6 182.59C836.85 138.59 918.19 120.98 969.89 120.98C1059.28 120.98 1115.83 182.59 1115.83 182.59H800.6Z" fill="#1DAA4F"/>
  <path d="M1800 182.59H1588.73C1624.99 138.59 1706.32 120.98 1758.02 120.98C1772.15 120.979 1786.23 122.518 1800.02 125.57L1800 182.59Z" fill="#1DAA4F"/>
  <path d="M0 182.59H123.16C123.16 182.59 88.16 144.47 32.88 144.47C21.8896 144.418 10.9072 145.069 0 146.42L0 182.59Z" fill="#68A81E"/>
  <path d="M0 182.59H305.77C305.77 182.59 244.44 115.77 147.5 115.77C86.37 115.77 36.12 125.5 0 148.6L0 182.59Z" fill="#D6CD21"/>
  <path d="M258.13 182.69C292.2 159.56 356.35 150.4 441.72 150.4C538.66 150.43 600 182.69 600 182.69H258.13Z" fill="#1DAA4F"/>
  <path d="M456.17 114.75H450.11V156.78H456.17V114.75Z" fill="#A38948"/>
  <path d="M435.54 122.06L427.89 124.54L430.61 126.13L428.32 130.03L431.5 128.9L431.3 131.39L438.83 125.9L435.54 122.06Z" fill="#D3D329"/>
  <path d="M464.3 129.21L470.91 135.76L470.38 131.4L472.32 132.76L471.99 129.79L475.25 129.05L471.21 127.81L467.17 123.83L464.3 129.21Z" fill="#D3D329"/>
  <path d="M443.21 133.24L441.1 140.49L445.4 139.58L448.7 143.25L451.74 139.94L459.91 141.22L461.75 139.94L462.63 140.3L462.11 135.17L443.21 133.24Z" fill="#D3D329"/>
  <path d="M441.31 136.59L464.07 138.24L459.57 126.68L466.73 132.74L470.76 125.4L454.89 114.75H448.83L433.88 121.73L436.82 128.24L446.18 125.22L441.31 136.59Z" fill="#A0754E"/>
  <path d="M457.77 156.78L459.87 151.71L453.81 154.59L452.32 151.32L449.71 154.05L446.29 152.73L448.44 156.78H457.77Z" fill="#498E1C"/>
  <path d="M452.69 118.24C457.097 118.24 460.67 114.667 460.67 110.26C460.67 105.853 457.097 102.28 452.69 102.28C448.283 102.28 444.71 105.853 444.71 110.26C444.71 114.667 448.283 118.24 452.69 118.24Z" fill="#E89F1F"/>
  <path d="M456.91 109.78C457.954 109.78 458.8 108.934 458.8 107.89C458.8 106.846 457.954 106 456.91 106C455.866 106 455.02 106.846 455.02 107.89C455.02 108.934 455.866 109.78 456.91 109.78Z" fill="#A0754E"/>
  <path d="M447.68 112.87C448.724 112.87 449.57 112.024 449.57 110.98C449.57 109.936 448.724 109.09 447.68 109.09C446.636 109.09 445.79 109.936 445.79 110.98C445.79 112.024 446.636 112.87 447.68 112.87Z" fill="#A0754E"/>
  <path d="M439.54 113.62L465.11 102.23L458.7 101.47L456.79 98.39L443.45 104.77L443.72 108.44L439.54 113.62Z" fill="#A38948"/>
  <path d="M449.2 114.01C450.016 114.431 450.911 114.676 451.827 114.73C452.743 114.783 453.661 114.643 454.52 114.32C455.28 113.972 455.942 113.44 456.443 112.771C456.944 112.102 457.27 111.317 457.39 110.49C457.39 110.49 459.11 114.49 455.47 116.21C451.83 117.93 449.2 114.01 449.2 114.01Z" fill="#A0754E"/>
  <path d="M219.74 55.9399H193.34V182.48H219.74V55.9399Z" fill="#CC4242"/>
  <path d="M193.33 55.9399H166.93V182.48H193.33V55.9399Z" fill="#C62626"/>
  <path d="M193.34 31.49C178.75 31.49 166.93 42.49 166.93 55.94H219.75C219.75 42.44 207.92 31.49 193.34 31.49Z" fill="#93897F"/>
  <path d="M206.54 34.7701C202.474 32.6068 197.936 31.4802 193.33 31.4901C178.75 31.4901 166.92 42.4901 166.92 55.9401H193.33C193.33 46.8901 198.65 39.0001 206.54 34.7701Z" fill="#82786E"/>
  <path d="M181.63 55.9399H178.7V182.48H181.63V55.9399Z" fill="#93897F"/>
  <path d="M178.66 55.9399H175.73V182.48H178.66V55.9399Z" fill="#82786E"/>
  <path d="M381.17 182.56H373.4C373.4 182.56 373.68 173.72 379.09 172.56C381.691 171.957 384.417 172.213 386.86 173.29C386.86 173.29 386.86 164.63 395.05 164.05C403.24 163.47 404.65 169.8 404.65 169.8C405.716 169.225 406.945 169.029 408.136 169.244C409.328 169.458 410.411 170.07 411.21 170.98C414.4 174.21 412.87 177.43 412.87 177.43C413.86 177.958 414.728 178.689 415.417 179.575C416.106 180.46 416.601 181.481 416.87 182.57L381.17 182.56Z" fill="#498E1C"/>
  <path d="M315.38 113.87H205.76V182.5H315.38V113.87Z" fill="#C62626"/>
  <path d="M400.79 182.5H313.77V113.87L328.74 92.6201L357.28 76.3101L384.45 92.6201L400.79 113.87V182.5Z" fill="#CC4242"/>
  <path d="M387.9 133.24H384.45V182.42H387.9V133.24Z" fill="white"/>
  <path d="M334.42 182.42V178.01L383.91 134.97L386.17 137.57L334.42 182.42Z" fill="white"/>
  <path d="M384.35 181.56V177.15L334.86 134.11L332.6 136.71L384.35 181.56Z" fill="white"/>
  <path d="M301.78 133.24H298.33V153.71H301.78V133.24Z" fill="white"/>
  <path d="M301.78 136.69V133.24H276.06V136.69H301.78Z" fill="white"/>
  <path d="M279.5 133.24H276.05V153.71H279.5V133.24Z" fill="white"/>
  <path d="M301.77 153.72V150.27H276.05V153.72H301.77Z" fill="white"/>
  <path d="M247.83 132.89H244.38V153.36H247.83V132.89Z" fill="white"/>
  <path d="M247.83 136.34V132.89H222.11V136.34H247.83Z" fill="white"/>
  <path d="M225.55 132.89H222.1V153.36H225.55V132.89Z" fill="white"/>
  <path d="M247.83 153.37V149.92H222.11V153.37H247.83Z" fill="white"/>
  <path d="M320.52 182.58H328.73C328.73 182.58 328.44 173.74 322.73 172.58C317.02 171.42 314.51 173.31 314.51 173.31C314.51 173.31 314.51 164.65 305.85 164.07C297.19 163.49 297.24 171.55 297.24 171.55C296.11 170.977 294.827 170.781 293.577 170.992C292.328 171.202 291.179 171.808 290.3 172.72C286.92 175.95 288.54 179.18 288.54 179.18C288.54 179.18 283.69 178.96 282.81 182.59L320.52 182.58Z" fill="#498E1C"/>
  <path d="M404.683 118.892L407.37 116.727L385.567 89.6682L382.881 91.8328L404.683 118.892Z" fill="#4C3109"/>
  <path d="M353.74 75.25L357.35 73.17L385.56 89.64L383.83 92.62L353.74 75.25Z" fill="#4C3109"/>
  <path d="M357.35 73.17H252.45L224.01 88.11L224 88.1L202.2 115.16L204.77 118.21L309.78 118.86L328.79 90.53L357.35 73.17Z" fill="#664922"/>
  <path d="M331.567 91.8289L328.88 89.6643L307.077 116.723L309.764 118.888L331.567 91.8289Z" fill="#4C3109"/>
  <path d="M360.71 75.26L357.35 73.17L328.89 89.65L330.62 92.64L360.71 75.26Z" fill="#4C3109"/>
  <path d="M307.1 116.7L202.2 115.16L204.77 118.21L309.78 118.86L307.1 116.7Z" fill="#4C3109"/>
  <path d="M373.16 98.39H369.71V118.86H373.16V98.39Z" fill="white"/>
  <path d="M373.16 101.84V98.39H347.44V101.84H373.16Z" fill="white"/>
  <path d="M350.89 98.39H347.44V118.86H350.89V98.39Z" fill="white"/>
  <path d="M373.15 118.87V115.42H347.43V118.87H373.15Z" fill="white"/>
  <path d="M225.45 182.59H233.67C233.67 182.59 233.38 173.75 227.67 172.59C221.96 171.43 219.45 173.33 219.45 173.33C219.45 173.33 219.45 164.67 210.8 164.08C202.15 163.49 200.65 169.84 200.65 169.84C199.521 169.264 198.236 169.066 196.986 169.277C195.736 169.488 194.588 170.095 193.71 171.01C190.33 174.24 191.95 177.47 191.95 177.47C190.93 177.985 190.03 178.708 189.308 179.594C188.586 180.48 188.059 181.507 187.76 182.61L225.45 182.59Z" fill="#498E1C"/>
  <path d="M387.89 133.24H334.42H332.06H330.97V182.42H334.42V136.69H387.89V133.24Z" fill="white"/>
  <path d="M1064.82 156.5H1013.06V181.95H1064.82V156.5Z" fill="#BF5C49"/>
  <path d="M1067.84 143.56H1010.04V150.03H1067.84V143.56Z" fill="#BF5C49"/>
  <path d="M1042.2 102.43L1019.64 79.87V79.86L1015.07 75.28L1015.06 75.29V75.28L1010.48 79.86L1010.49 79.87L987.92 102.43L992.5 107.01L1015.06 84.44L1037.63 107.01L1042.2 102.43Z" fill="#A0835C"/>
  <path d="M1064.82 150.03H1013.06V156.5H1064.82V150.03Z" fill="#AA5044"/>
  <path d="M1040.45 123.28V116.81H1037.43V123.28H1031.17V140.1H1046.7V123.28H1040.45Z" fill="#A0835C"/>
  <path d="M1060.35 75.28L1055.92 79.71H1059.21L1083.37 105.16H1081.08L1082.92 107.01L1087.5 102.43L1060.35 75.28Z" fill="#A0835C"/>
  <path d="M1058.35 105.16V110.34H1019.53V88.9101L1015.06 84.4401L1013.06 86.4501V143.56H1019.53V116.81H1058.35V143.56H1064.82V105.16H1058.35Z" fill="#C6B381"/>
  <path d="M1019.53 105.16H1035.41L1035.59 104.97L1019.53 88.91V105.16Z" fill="#AA5044"/>
  <path d="M1013.06 86.4501L994.34 105.16H1013.06V86.4501Z" fill="#AA5044"/>
  <path d="M1019.64 79.8601V79.8701L1042.2 102.43L1039.47 105.16H1083.37L1059.21 79.7101H1019.5L1019.64 79.8601Z" fill="#BF5C49"/>
  <path d="M1045.96 124.07H1031.84V127.65H1045.96V124.07Z" fill="#0B5CFF"/>
  <path d="M1524.39 182.54H1366.42L1362.17 153.07L1378.64 106.59L1445.8 77.61L1492.82 93.15L1513.3 128.43L1524.39 182.54Z" fill="#513C2B"/>
  <path d="M1445.8 77.61L1378.64 106.59L1376.02 123.7L1367.47 157.05L1374.02 172.41L1432.42 179.05L1471.06 173.05L1502.48 153.5L1514.85 135.98L1513.69 128.07L1492.82 93.15L1445.8 77.61Z" fill="#7F6041"/>
  <path d="M1533.12 182.59H1450.94L1457.9 150.03L1486.22 127L1514.26 133.81L1527.26 152.29L1533.12 182.59Z" fill="#513C2B"/>
  <path d="M1438.18 136.08H1429.33V182.35H1438.18V136.08Z" fill="#AF7E45"/>
  <path d="M1396.05 136.08H1387.2V182.35H1396.05V136.08Z" fill="#AF7E45"/>
  <path d="M1383.07 127.24V136.09H1441.91V127.24H1383.07Z" fill="#AF7E45"/>
  <path d="M1429.33 136.08H1396.04V182.35H1429.33V136.08Z" fill="#473321"/>
  <path d="M1486.22 127L1466.63 146.28L1468.03 168.4L1488.52 174.68L1522.45 173.05L1528.95 161.06L1527.26 152.29L1514.26 133.81L1486.22 127Z" fill="#7F6041"/>
  <path d="M1429.34 178.54H1350.8V182.35H1429.34V178.54Z" fill="#685D53"/>
  <path d="M1375.85 160.91L1379.64 176.2H1402.42L1405.59 160.91H1375.85Z" fill="#827C78"/>
  <path d="M1383.5 178.54C1384.22 178.54 1384.81 177.953 1384.81 177.23C1384.81 176.506 1384.22 175.92 1383.5 175.92C1382.78 175.92 1382.19 176.506 1382.19 177.23C1382.19 177.953 1382.78 178.54 1383.5 178.54Z" fill="#706861"/>
  <path d="M1399.62 178.54C1400.34 178.54 1400.93 177.953 1400.93 177.23C1400.93 176.506 1400.34 175.92 1399.62 175.92C1398.9 175.92 1398.31 176.506 1398.31 177.23C1398.31 177.953 1398.9 178.54 1399.62 178.54Z" fill="#706861"/>
  <path d="M1382.19 155.19L1378.53 158.33L1377.83 160.91H1403.2L1402.68 158.05L1399.13 154.55L1396.51 155.36L1391.33 156.82L1385.86 154.55L1382.19 155.19Z" fill="#685D53"/>
  <path d="M1656 183.35H1666.28C1666.28 183.35 1665.91 172.29 1658.76 170.83C1651.61 169.37 1648.48 171.74 1648.48 171.74C1648.48 171.74 1648.48 160.92 1637.65 160.18C1626.82 159.44 1624.96 167.38 1624.96 167.38C1623.55 166.66 1621.94 166.414 1620.38 166.679C1618.81 166.944 1617.38 167.705 1616.28 168.85C1612.06 172.85 1614.08 176.92 1614.08 176.92C1612.81 177.567 1611.68 178.473 1610.78 179.58C1609.88 180.688 1609.22 181.972 1608.84 183.35H1656Z" fill="#498E1C"/>
  <path d="M1635.6 183.24H1700.46V107.39L1635.6 107.39V183.24Z" fill="#B9B6CC"/>
  <path d="M1695.31 183.24H1754.99V107.39L1695.31 107.39V183.24Z" fill="#9F97C6"/>
  <path d="M1695.29 52.1201C1728.29 52.1201 1754.99 76.8601 1754.99 107.38H1635.6C1635.6 76.8601 1662.32 52.1201 1695.29 52.1201Z" fill="#938F93"/>
  <path d="M1665.46 59.5301C1674.64 54.6386 1684.89 52.0932 1695.3 52.1201C1728.3 52.1201 1755 76.8601 1755 107.38H1695.3C1695.3 86.9301 1683.3 69.0901 1665.46 59.5301Z" fill="#828282"/>
  <path d="M1721.74 183.24H1728.37V107.39H1721.74V183.24Z" fill="#938F93"/>
  <path d="M1728.46 183.24H1735.09V107.39H1728.46V183.24Z" fill="#828282"/>
  <path d="M1649.17 96H1670.34C1670.34 96 1677.68 55.62 1716.96 56.11C1716.96 56.11 1707.88 50.97 1693.59 52.05C1693.59 52.05 1659 57.75 1649.17 96Z" fill="#605858"/>
  <path d="M1707.24 52.8599C1707.24 52.8599 1701.89 51.4599 1692.99 52.0499C1684.09 53.7999 1656.82 64.3199 1649.17 96.0499H1652.72C1652.72 96.0499 1656.42 79.4299 1669.72 66.9299C1678.17 58.9499 1692 52.6899 1707.24 52.8599Z" fill="#828282"/>
  <path d="M1649.2 79.5801L1656.63 87.0101C1658.34 88.6401 1665.55 84.1501 1672.77 76.9301C1679.99 69.7101 1684.48 62.5101 1682.84 60.7901L1675.41 53.3601L1649.2 79.5801Z" fill="#9F97C6"/>
  <path d="M1665.92 82.99C1666.92 82.22 1667.92 81.37 1669.02 80.44L1658.68 70.1L1655.85 72.92L1665.92 82.99Z" fill="#ADA7D6"/>
  <path d="M1669.06 80.4C1670.06 79.56 1671.06 78.65 1672 77.69L1661.54 67.23L1658.71 70.06L1669.06 80.4Z" fill="#B9B6CC"/>
  <path d="M1643.1 73.49L1650.53 80.92C1652.25 82.56 1659.46 78.06 1666.67 70.85C1673.88 63.64 1678.39 56.42 1676.75 54.71L1669.32 47.28L1643.1 73.49Z" fill="#938F93"/>
  <path d="M1631.41 70.47L1641.73 80.79C1644.11 83.07 1654.13 76.79 1664.15 66.79C1674.17 56.79 1680.43 46.79 1678.15 44.37L1667.82 34.04L1631.41 70.47Z" fill="#9F97C6"/>
  <path d="M1654.64 75.21C1656.03 74.1434 1657.46 72.96 1658.95 71.66L1644.58 57.29L1640.65 61.22L1654.64 75.21Z" fill="#ADA7D6"/>
  <path d="M1659 71.6201C1660.34 70.4401 1661.71 69.1801 1663.09 67.8401L1648.56 53.3201L1644.63 57.2501L1659 71.6201Z" fill="#B9B6CC"/>
  <path d="M1624.56 63.63L1634.89 73.95C1637.27 76.22 1647.28 69.95 1657.31 59.95C1667.34 49.95 1673.58 39.95 1671.31 37.53L1661 27.2L1624.56 63.63Z" fill="#938F93"/>
  <path d="M1615.17 61.3101L1627.5 73.6301C1630.34 76.3501 1642.3 68.8801 1654.27 56.9101C1666.24 44.9401 1673.71 32.9801 1670.99 30.1401L1658.66 17.8201L1615.17 61.3101Z" fill="#9F97C6"/>
  <path d="M1642.91 66.9601C1644.57 65.6901 1646.3 64.2701 1648.06 62.7301L1630.9 45.5701L1626.21 50.2601L1642.91 66.9601Z" fill="#ADA7D6"/>
  <path d="M1648.12 62.6901C1649.73 61.2767 1651.36 59.7734 1653 58.1801L1635.65 40.8301L1631 45.5101L1648.12 62.6901Z" fill="#B9B6CC"/>
  <path d="M1642.6 45.2409C1654.61 33.2284 1662.09 21.2267 1659.3 18.4345C1656.5 15.6422 1644.5 23.1167 1632.49 35.1293C1620.48 47.1418 1613 59.1434 1615.8 61.9357C1618.59 64.7279 1630.59 57.2534 1642.6 45.2409Z" fill="#B9B6CC"/>
  <path d="M1654.41 21.1C1656.77 23.46 1650.64 33.43 1640.72 43.36C1630.8 53.29 1620.83 59.36 1618.46 57.05C1616.09 54.74 1619.81 41.93 1629.74 32.05C1639.67 22.17 1652 18.69 1654.41 21.1Z" fill="#605858"/>
  <path d="M1645.06 26.7048C1646.89 25.65 1648.1 24.3218 1647.76 23.7383C1647.42 23.1547 1645.67 23.5369 1643.84 24.5917C1642.01 25.6466 1640.81 26.9747 1641.14 27.5583C1641.48 28.1418 1643.23 27.7597 1645.06 26.7048Z" fill="#B9B6CC"/>
  <path d="M1640.93 32.565C1642.58 31.2416 1643.57 29.7434 1643.14 29.2187C1642.72 28.694 1641.05 29.3414 1639.4 30.6648C1637.76 31.9882 1636.77 33.4864 1637.19 34.0112C1637.62 34.5359 1639.29 33.8884 1640.93 32.565Z" fill="#B9B6CC"/>
  <path d="M1637.88 29.2983C1638.74 28.6124 1639.25 27.8367 1639.03 27.5657C1638.81 27.2947 1637.95 27.6311 1637.09 28.3171C1636.24 29.003 1635.73 29.7787 1635.95 30.0497C1636.16 30.3207 1637.03 29.9843 1637.88 29.2983Z" fill="#B9B6CC"/>
  <path d="M1673.14 147.63H1654.77V183.23H1673.14V147.63Z" fill="#938F93"/>
  <path d="M1654.78 147.63H1649.2V183.23H1654.78V147.63Z" fill="#828282"/>
  <path d="M1741.4 174.3C1741.98 173.294 1742.82 172.466 1743.84 171.907C1744.86 171.348 1746.01 171.079 1747.17 171.13C1747.17 171.13 1749.65 164.13 1755.68 164.13C1761.9 163.52 1762.97 170.13 1762.97 170.13C1762.97 170.13 1765.53 168 1767.97 171.35C1768.68 172.294 1769.16 173.388 1769.38 174.548C1769.6 175.707 1769.55 176.902 1769.24 178.04C1770.89 179.36 1771.97 181.271 1772.24 183.37H1740.24C1740.24 183.37 1739.34 177.89 1741.4 174.3Z" fill="#498E1C"/>
  <path d="M1800.01 187.88H1789.08V277.21H1800.01V187.88Z" fill="#F99344"/>
  <path d="M1790.41 187.88H1775.89V277.21H1790.41V187.88Z" fill="#DF843D"/>
  <path d="M1112.26 249.62H1097.74V338.95H1112.26V249.62Z" fill="#D7781B"/>
  <path d="M1099.08 249.62H1084.56V338.95H1099.08V249.62Z" fill="#C26C17"/>
  <path d="M1585.15 230.06H1570.63V319.39H1585.15V230.06Z" fill="#D7781B"/>
  <path d="M1571.97 230.06H1557.45V319.39H1571.97V230.06Z" fill="#C26C17"/>
  <path d="M795.83 128.64H781.31V217.97H795.83V128.64Z" fill="#D7781B"/>
  <path d="M782.65 128.64H768.13V217.97H782.65V128.64Z" fill="#C26C17"/>
  <path d="M137.02 134.39H122.5V223.72H137.02V134.39Z" fill="#D7781B"/>
  <path d="M123.83 134.39H109.31V223.72H123.83V134.39Z" fill="#C26C17"/>
  <path d="M296.66 249.62H282.14V338.95H296.66V249.62Z" fill="#D7781B"/>
  <path d="M283.47 249.62H268.95V338.95H283.47V249.62Z" fill="#C26C17"/>
  <path d="M1414.79 298.65H1400.27V358.72H1414.79V298.65Z" fill="#D7781B"/>
  <path d="M1401.6 298.65H1387.08V358.72H1401.6V298.65Z" fill="#C26C17"/>
  <path d="M654.93 199.02H640.41V288.35H654.93V199.02Z" fill="#F99344"/>
  <path d="M641.74 199.02H627.22V288.35H641.74V199.02Z" fill="#DF843D"/>
  <path d="M427.89 239.69H413.37V329.02H427.89V239.69Z" fill="#F99344"/>
  <path d="M414.7 239.69H400.18V329.02H414.7V239.69Z" fill="#DF843D"/>
  <path d="M873.22 239.69H858.7V329.02H873.22V239.69Z" fill="#F99344"/>
  <path d="M860.04 239.69H845.52V329.02H860.04V239.69Z" fill="#DF843D"/>
  <path d="M1259.52 195.02H1245V284.35H1259.52V195.02Z" fill="#F99344"/>
  <path d="M1246.34 195.02H1231.82V284.35H1246.34V195.02Z" fill="#DF843D"/>
  <path d="M76.7099 243.69H62.1899V333.02H76.7099V243.69Z" fill="#F99344"/>
  <path d="M63.52 243.69H49V333.02H63.52V243.69Z" fill="#DF843D"/>
  <path d="M641.74 203.14C663.34 203.14 680.85 185.63 680.85 164.03C680.85 142.43 663.34 124.92 641.74 124.92C620.14 124.92 602.63 142.43 602.63 164.03C602.63 185.63 620.14 203.14 641.74 203.14Z" fill="#68A81E"/>
  <path opacity="0.1" d="M641.74 194.52C632.11 194.516 622.82 190.961 615.648 184.535C608.476 178.108 603.926 169.262 602.87 159.69C602.714 161.121 602.634 162.56 602.63 164C602.63 174.373 606.751 184.32 614.085 191.655C621.42 198.989 631.367 203.11 641.74 203.11C652.113 203.11 662.06 198.989 669.395 191.655C676.73 184.32 680.85 174.373 680.85 164C680.851 162.56 680.771 161.121 680.61 159.69C679.558 169.264 675.01 178.112 667.837 184.54C660.664 190.967 651.371 194.521 641.74 194.52V194.52Z" fill="black"/>
  <path d="M782.65 134.92C804.25 134.92 821.76 117.41 821.76 95.8099C821.76 74.2101 804.25 56.7 782.65 56.7C761.05 56.7 743.54 74.2101 743.54 95.8099C743.54 117.41 761.05 134.92 782.65 134.92Z" fill="#68A81E"/>
  <path opacity="0.1" d="M782.65 126.3C773.022 126.301 763.732 122.75 756.559 116.327C749.387 109.904 744.836 101.06 743.78 91.49C743.619 92.921 743.539 94.36 743.54 95.8C743.54 106.173 747.661 116.12 754.995 123.455C762.33 130.789 772.277 134.91 782.65 134.91C793.023 134.91 802.97 130.789 810.305 123.455C817.639 116.12 821.76 106.173 821.76 95.8C821.756 94.3601 821.676 92.9214 821.52 91.49C820.459 101.058 815.908 109.9 808.736 116.322C801.565 122.744 792.277 126.297 782.65 126.3V126.3Z" fill="black"/>
  <path d="M1246.33 219.55C1267.93 219.55 1285.44 202.04 1285.44 180.44C1285.44 158.84 1267.93 141.33 1246.33 141.33C1224.73 141.33 1207.22 158.84 1207.22 180.44C1207.22 202.04 1224.73 219.55 1246.33 219.55Z" fill="#68A81E"/>
  <path opacity="0.1" d="M1246.33 210.94C1236.7 210.937 1227.42 207.385 1220.24 200.962C1213.07 194.54 1208.52 185.698 1207.46 176.13C1207.3 177.561 1207.22 179 1207.22 180.44C1207.38 190.706 1211.58 200.495 1218.9 207.697C1226.21 214.898 1236.07 218.935 1246.33 218.935C1256.6 218.935 1266.46 214.898 1273.78 207.697C1281.09 200.495 1285.29 190.706 1285.45 180.44C1285.45 179 1285.36 177.561 1285.2 176.13C1284.14 185.7 1279.59 194.544 1272.42 200.967C1265.25 207.39 1255.96 210.941 1246.33 210.94V210.94Z" fill="black"/>
  <path d="M1630.96 318.17H1616.44V359.05H1630.96V318.17Z" fill="#F99344"/>
  <path d="M1617.77 318.17H1603.25V359.05H1617.77V318.17Z" fill="#DF843D"/>
  <path d="M1617.77 342.7C1639.37 342.7 1656.88 325.19 1656.88 303.59C1656.88 281.99 1639.37 264.48 1617.77 264.48C1596.17 264.48 1578.66 281.99 1578.66 303.59C1578.66 325.19 1596.17 342.7 1617.77 342.7Z" fill="#68A81E"/>
  <path opacity="0.1" d="M1617.77 334.08C1608.14 334.077 1598.86 330.526 1591.69 324.106C1584.52 317.685 1579.96 308.847 1578.9 299.28C1578.74 300.711 1578.66 302.15 1578.66 303.59C1578.66 313.963 1582.78 323.91 1590.12 331.245C1597.45 338.58 1607.4 342.7 1617.77 342.7C1628.14 342.7 1638.09 338.58 1645.42 331.245C1652.76 323.91 1656.88 313.963 1656.88 303.59C1656.88 302.15 1656.8 300.711 1656.64 299.28C1655.58 308.848 1651.03 317.69 1643.86 324.111C1636.69 330.532 1627.4 334.082 1617.77 334.08Z" fill="black"/>
  <path d="M1560.46 127.49H1545.94V216.82H1560.46V127.49Z" fill="#F99344"/>
  <path d="M1547.27 127.49H1532.75V216.82H1547.27V127.49Z" fill="#DF843D"/>
  <path d="M1800 133.22C1794.17 131.523 1788.03 131.205 1782.06 132.293C1776.08 133.381 1770.45 135.843 1765.59 139.487C1760.74 143.13 1756.8 147.853 1754.08 153.283C1751.37 158.713 1749.95 164.7 1749.95 170.77C1749.95 176.84 1751.37 182.827 1754.08 188.257C1756.8 193.687 1760.74 198.41 1765.59 202.053C1770.45 205.696 1776.08 208.159 1782.06 209.247C1788.03 210.335 1794.17 210.017 1800 208.32V133.22Z" fill="#68A81E"/>
  <path opacity="0.1" d="M1800 199.69C1794.52 201.279 1788.76 201.651 1783.12 200.779C1777.49 199.908 1772.11 197.815 1767.37 194.647C1762.62 191.478 1758.63 187.311 1755.67 182.437C1752.7 177.562 1750.84 172.099 1750.21 166.43C1750.05 167.861 1749.96 169.3 1749.96 170.74C1749.96 176.805 1751.37 182.787 1754.09 188.212C1756.8 193.637 1760.73 198.357 1765.58 202C1770.43 205.642 1776.06 208.106 1782.03 209.198C1788 210.29 1794.13 209.979 1799.96 208.29L1800 199.69Z" fill="black"/>
  <path d="M413.37 271.66C434.97 271.66 452.48 254.15 452.48 232.55C452.48 210.95 434.97 193.44 413.37 193.44C391.77 193.44 374.26 210.95 374.26 232.55C374.26 254.15 391.77 271.66 413.37 271.66Z" fill="#FAC038"/>
  <path opacity="0.1" d="M413.37 263.04C403.743 263.042 394.454 259.492 387.282 253.071C380.11 246.65 375.559 237.808 374.5 228.24C374.339 229.671 374.259 231.11 374.26 232.55C374.26 242.923 378.381 252.87 385.715 260.205C393.05 267.539 402.997 271.66 413.37 271.66C423.743 271.66 433.69 267.539 441.025 260.205C448.359 252.87 452.48 242.923 452.48 232.55C452.476 231.11 452.396 229.671 452.24 228.24C451.177 237.807 446.625 246.645 439.453 253.066C432.282 259.486 422.995 263.037 413.37 263.04V263.04Z" fill="black"/>
  <path d="M123.83 162.94C145.43 162.94 162.94 145.43 162.94 123.83C162.94 102.23 145.43 84.72 123.83 84.72C102.23 84.72 84.72 102.23 84.72 123.83C84.72 145.43 102.23 162.94 123.83 162.94Z" fill="#68A81E"/>
  <path opacity="0.1" d="M123.83 154.32C114.21 154.307 104.932 150.75 97.7688 144.329C90.6057 137.907 86.0599 129.072 85 119.51C84.8442 120.941 84.7641 122.38 84.76 123.82C84.76 134.193 88.8805 144.14 96.2151 151.475C103.55 158.81 113.497 162.93 123.87 162.93C134.243 162.93 144.19 158.81 151.525 151.475C158.86 144.14 162.98 134.193 162.98 123.82C162.981 122.38 162.901 120.941 162.74 119.51C161.683 129.087 157.127 137.936 149.946 144.361C142.765 150.785 133.465 154.331 123.83 154.32V154.32Z" fill="black"/>
  <path d="M62.19 255.39C83.7898 255.39 101.3 237.88 101.3 216.28C101.3 194.68 83.7898 177.17 62.19 177.17C40.5901 177.17 23.08 194.68 23.08 216.28C23.08 237.88 40.5901 255.39 62.19 255.39Z" fill="#68A81E"/>
  <path opacity="0.1" d="M62.19 246.77C52.5633 246.772 43.2743 243.222 36.1021 236.801C28.9299 230.38 24.3786 221.538 23.32 211.97C23.1592 213.401 23.079 214.84 23.08 216.28C23.08 226.653 27.2005 236.6 34.535 243.935C41.8696 251.269 51.8173 255.39 62.19 255.39C72.5626 255.39 82.5104 251.269 89.8449 243.935C97.1795 236.6 101.3 226.653 101.3 216.28C101.296 214.84 101.216 213.401 101.06 211.97C99.997 221.537 95.4445 230.375 88.2732 236.796C81.1019 243.216 71.8154 246.767 62.19 246.77V246.77Z" fill="black"/>
  <path d="M1337.91 271.7H1329.08V326.01H1337.91V271.7Z" fill="#F99344"/>
  <path d="M1329.9 271.7H1321.07V326.01H1329.9V271.7Z" fill="#DF843D"/>
  <path d="M1329.89 286.62C1343.02 286.62 1353.67 275.973 1353.67 262.84C1353.67 249.707 1343.02 239.06 1329.89 239.06C1316.76 239.06 1306.11 249.707 1306.11 262.84C1306.11 275.973 1316.76 286.62 1329.89 286.62Z" fill="#68A81E"/>
  <path opacity="0.1" d="M1329.89 281.38C1324.04 281.376 1318.39 279.216 1314.03 275.313C1309.67 271.409 1306.91 266.036 1306.26 260.22C1306.16 261.09 1306.11 261.965 1306.11 262.84C1306.11 269.147 1308.62 275.195 1313.07 279.655C1317.53 284.115 1323.58 286.62 1329.89 286.62C1336.2 286.62 1342.25 284.115 1346.7 279.655C1351.16 275.195 1353.67 269.147 1353.67 262.84C1353.67 261.964 1353.62 261.09 1353.52 260.22C1352.88 266.038 1350.11 271.413 1345.75 275.318C1341.39 279.222 1335.74 281.381 1329.89 281.38V281.38Z" fill="black"/>
  <path d="M1695.13 239.12H1686.3V293.43H1695.13V239.12Z" fill="#F99344"/>
  <path d="M1687.12 239.12H1678.29V293.43H1687.12V239.12Z" fill="#DF843D"/>
  <path d="M1687.11 254.04C1700.24 254.04 1710.89 243.393 1710.89 230.26C1710.89 217.127 1700.24 206.48 1687.11 206.48C1673.98 206.48 1663.33 217.127 1663.33 230.26C1663.33 243.393 1673.98 254.04 1687.11 254.04Z" fill="#FACEE7"/>
  <path opacity="0.1" d="M1687.11 248.79C1681.26 248.787 1675.61 246.626 1671.25 242.723C1666.89 238.819 1664.13 233.446 1663.48 227.63C1663.38 228.5 1663.33 229.375 1663.33 230.25C1663.33 236.557 1665.84 242.605 1670.3 247.065C1674.75 251.525 1680.8 254.03 1687.11 254.03C1693.42 254.03 1699.47 251.525 1703.93 247.065C1708.38 242.605 1710.89 236.557 1710.89 230.25C1710.89 229.375 1710.84 228.5 1710.74 227.63C1710.1 233.448 1707.33 238.823 1702.97 242.728C1698.61 246.632 1692.96 248.791 1687.11 248.79V248.79Z" fill="black"/>
  <path d="M983.76 318.19H974.93V359.05H983.76V318.19Z" fill="#F99344"/>
  <path d="M975.75 318.19H966.92V359.05H975.75V318.19Z" fill="#DF843D"/>
  <path d="M975.74 333.1C988.873 333.1 999.52 322.453 999.52 309.32C999.52 296.187 988.873 285.54 975.74 285.54C962.607 285.54 951.96 296.187 951.96 309.32C951.96 322.453 962.607 333.1 975.74 333.1Z" fill="#68A81E"/>
  <path opacity="0.1" d="M975.74 327.86C969.886 327.858 964.239 325.698 959.879 321.792C955.519 317.885 952.752 312.508 952.11 306.69C952.014 307.56 951.964 308.435 951.96 309.31C951.96 315.617 954.465 321.665 958.925 326.125C963.385 330.585 969.433 333.09 975.74 333.09C982.047 333.09 988.095 330.585 992.555 326.125C997.015 321.665 999.52 315.617 999.52 309.31C999.521 308.434 999.471 307.56 999.37 306.69C998.73 312.509 995.964 317.888 991.604 321.794C987.243 325.701 981.594 327.861 975.74 327.86V327.86Z" fill="black"/>
  <path d="M1505.81 181.52H1496.98V235.83H1505.81V181.52Z" fill="#F99344"/>
  <path d="M1497.8 181.52H1488.97V235.83H1497.8V181.52Z" fill="#DF843D"/>
  <path d="M1497.79 196.44C1510.92 196.44 1521.57 185.793 1521.57 172.66C1521.57 159.527 1510.92 148.88 1497.79 148.88C1484.66 148.88 1474.01 159.527 1474.01 172.66C1474.01 185.793 1484.66 196.44 1497.79 196.44Z" fill="#68A81E"/>
  <path opacity="0.1" d="M1497.79 191.2C1491.94 191.197 1486.29 189.037 1481.93 185.133C1477.57 181.229 1474.81 175.856 1474.16 170.04C1474.06 170.92 1474.01 171.804 1474 172.69C1474.13 178.911 1476.69 184.834 1481.13 189.189C1485.58 193.543 1491.55 195.982 1497.78 195.982C1504 195.982 1509.97 193.543 1514.42 189.189C1518.86 184.834 1521.42 178.911 1521.55 172.69C1521.55 171.815 1521.5 170.94 1521.41 170.07C1520.76 175.879 1517.99 181.243 1513.63 185.141C1509.27 189.038 1503.64 191.195 1497.79 191.2Z" fill="black"/>
  <path d="M1193.44 332.48H1184.61V358.72H1193.44V332.48Z" fill="#F99344"/>
  <path d="M1185.42 332.48H1176.59V358.72H1185.42V332.48Z" fill="#DF843D"/>
  <path d="M1185.42 347.4C1198.55 347.4 1209.2 336.753 1209.2 323.62C1209.2 310.487 1198.55 299.84 1185.42 299.84C1172.29 299.84 1161.64 310.487 1161.64 323.62C1161.64 336.753 1172.29 347.4 1185.42 347.4Z" fill="#68A81E"/>
  <path opacity="0.1" d="M1185.42 342.16C1179.57 342.161 1173.92 340.002 1169.56 336.098C1165.2 332.193 1162.43 326.818 1161.79 321C1161.69 321.87 1161.64 322.744 1161.64 323.62C1161.64 329.927 1164.15 335.975 1168.6 340.435C1173.06 344.895 1179.11 347.4 1185.42 347.4C1191.73 347.4 1197.78 344.895 1202.23 340.435C1206.69 335.975 1209.2 329.927 1209.2 323.62C1209.2 322.745 1209.15 321.87 1209.05 321C1208.41 326.818 1205.64 332.193 1201.28 336.098C1196.92 340.002 1191.27 342.161 1185.42 342.16V342.16Z" fill="black"/>
  <path d="M726.14 165.08H717.31V219.39H726.14V165.08Z" fill="#F99344"/>
  <path d="M718.12 165.08H709.29V219.39H718.12V165.08Z" fill="#DF843D"/>
  <path d="M718.12 180C731.253 180 741.9 169.353 741.9 156.22C741.9 143.087 731.253 132.44 718.12 132.44C704.987 132.44 694.34 143.087 694.34 156.22C694.34 169.353 704.987 180 718.12 180Z" fill="#68A81E"/>
  <path opacity="0.1" d="M718.12 174.76C712.267 174.761 706.62 172.602 702.259 168.698C697.899 164.793 695.133 159.418 694.49 153.6C694.389 154.47 694.339 155.344 694.34 156.22C694.34 162.527 696.845 168.575 701.305 173.035C705.765 177.495 711.813 180 718.12 180C724.427 180 730.475 177.495 734.935 173.035C739.395 168.575 741.9 162.527 741.9 156.22C741.901 155.344 741.851 154.47 741.75 153.6C741.107 159.418 738.341 164.793 733.981 168.698C729.62 172.602 723.973 174.761 718.12 174.76V174.76Z" fill="black"/>
  <path d="M133.56 317H124.73V359.06H133.56V317Z" fill="#F99344"/>
  <path d="M125.55 317H116.72V359.06H125.55V317Z" fill="#DF843D"/>
  <path d="M125.54 331.91C138.673 331.91 149.32 321.263 149.32 308.13C149.32 294.997 138.673 284.35 125.54 284.35C112.407 284.35 101.76 294.997 101.76 308.13C101.76 321.263 112.407 331.91 125.54 331.91Z" fill="#68A81E"/>
  <path opacity="0.1" d="M125.54 326.69C119.688 326.687 114.043 324.527 109.684 320.623C105.325 316.719 102.557 311.346 101.91 305.53C101.814 306.4 101.764 307.275 101.76 308.15C101.76 311.273 102.375 314.365 103.57 317.25C104.765 320.135 106.517 322.757 108.725 324.965C110.933 327.173 113.555 328.925 116.44 330.12C119.325 331.315 122.417 331.93 125.54 331.93C128.663 331.93 131.755 331.315 134.64 330.12C137.525 328.925 140.147 327.173 142.355 324.965C144.563 322.757 146.315 320.135 147.51 317.25C148.705 314.365 149.32 311.273 149.32 308.15C149.321 307.275 149.271 306.4 149.17 305.53C148.527 311.348 145.761 316.723 141.401 320.628C137.04 324.532 131.393 326.691 125.54 326.69V326.69Z" fill="black"/>
  <path d="M455.7 321.08H446.87V358.88H455.7V321.08Z" fill="#F99344"/>
  <path d="M447.68 321.08H438.85V358.88H447.68V321.08Z" fill="#DF843D"/>
  <path d="M447.68 335.99C460.813 335.99 471.46 325.343 471.46 312.21C471.46 299.077 460.813 288.43 447.68 288.43C434.547 288.43 423.9 299.077 423.9 312.21C423.9 325.343 434.547 335.99 447.68 335.99Z" fill="#68A81E"/>
  <path opacity="0.1" d="M447.68 330.75C441.82 330.759 436.162 328.604 431.793 324.699C427.423 320.795 424.648 315.414 424 309.59C423.908 310.46 423.861 311.335 423.86 312.21C423.795 315.373 424.362 318.518 425.528 321.459C426.693 324.4 428.434 327.079 430.648 329.339C432.863 331.599 435.505 333.395 438.422 334.621C441.339 335.846 444.471 336.478 447.635 336.478C450.799 336.478 453.931 335.846 456.848 334.621C459.765 333.395 462.407 331.599 464.622 329.339C466.836 327.079 468.577 324.4 469.742 321.459C470.908 318.518 471.475 315.373 471.41 312.21C471.409 311.335 471.362 310.46 471.27 309.59C470.624 315.399 467.862 320.767 463.511 324.669C459.16 328.572 453.525 330.737 447.68 330.75V330.75Z" fill="black"/>
  <path d="M712.65 273.52H703.82V327.83H712.65V273.52Z" fill="#F99344"/>
  <path d="M704.63 273.52H695.8V327.83H704.63V273.52Z" fill="#DF843D"/>
  <path d="M704.63 288.44C717.763 288.44 728.41 277.793 728.41 264.66C728.41 251.527 717.763 240.88 704.63 240.88C691.497 240.88 680.85 251.527 680.85 264.66C680.85 277.793 691.497 288.44 704.63 288.44Z" fill="#FACEE7"/>
  <path opacity="0.1" d="M704.63 283.19C698.777 283.191 693.13 281.032 688.769 277.128C684.409 273.223 681.643 267.848 681 262.03C680.905 262.9 680.855 263.775 680.85 264.65C680.85 270.957 683.355 277.005 687.815 281.465C692.275 285.925 698.323 288.43 704.63 288.43C710.937 288.43 716.985 285.925 721.445 281.465C725.905 277.005 728.41 270.957 728.41 264.65C728.41 263.775 728.36 262.9 728.26 262.03C727.617 267.848 724.851 273.223 720.491 277.128C716.13 281.032 710.483 283.191 704.63 283.19V283.19Z" fill="black"/>
  <path d="M352.82 323.58H346.89V344.76H352.82V323.58Z" fill="#F99344"/>
  <path d="M347.43 323.58H341.5V344.76H347.43V323.58Z" fill="#DF843D"/>
  <path d="M347.44 332.81C356.271 332.81 363.43 325.651 363.43 316.82C363.43 307.989 356.271 300.83 347.44 300.83C338.609 300.83 331.45 307.989 331.45 316.82C331.45 325.651 338.609 332.81 347.44 332.81Z" fill="#FACEE7"/>
  <path opacity="0.1" d="M347.44 329.28C343.505 329.277 339.709 327.824 336.778 325.199C333.847 322.574 331.985 318.961 331.55 315.05C331.482 315.634 331.448 316.222 331.45 316.81C331.45 321.053 333.136 325.123 336.136 328.124C339.137 331.124 343.207 332.81 347.45 332.81C351.693 332.81 355.763 331.124 358.764 328.124C361.764 325.123 363.45 321.053 363.45 316.81C363.452 316.222 363.418 315.634 363.35 315.05C362.914 318.964 361.05 322.58 358.115 325.206C355.179 327.831 351.378 329.282 347.44 329.28V329.28Z" fill="black"/>
  <path d="M204.41 239.12H195.58V293.43H204.41V239.12Z" fill="#F99344"/>
  <path d="M196.39 239.12H187.56V293.43H196.39V239.12Z" fill="#DF843D"/>
  <path d="M196.39 254.04C209.523 254.04 220.17 243.393 220.17 230.26C220.17 217.127 209.523 206.48 196.39 206.48C183.257 206.48 172.61 217.127 172.61 230.26C172.61 243.393 183.257 254.04 196.39 254.04Z" fill="#68A81E"/>
  <path opacity="0.1" d="M196.39 248.79C190.537 248.791 184.89 246.632 180.529 242.728C176.169 238.823 173.403 233.448 172.76 227.63C172.665 228.5 172.615 229.375 172.61 230.25C172.61 236.557 175.115 242.605 179.575 247.065C184.035 251.525 190.083 254.03 196.39 254.03C202.697 254.03 208.745 251.525 213.205 247.065C217.665 242.605 220.17 236.557 220.17 230.25C220.17 229.375 220.12 228.5 220.02 227.63C219.377 233.448 216.611 238.823 212.251 242.728C207.89 246.632 202.243 248.791 196.39 248.79V248.79Z" fill="black"/>
  <path d="M9.89995 315.18H1.06995V359.05H9.89995V315.18Z" fill="#F99344"/>
  <path d="M1.86999 315.18H0.109985V359.05H1.86999V315.18Z" fill="#DF843D"/>
  <path d="M958.42 167.83H943.9V257.16H958.42V167.83Z" fill="#F99344"/>
  <path d="M945.23 167.83H930.71V257.16H945.23V167.83Z" fill="#DF843D"/>
  <path d="M1036.81 244.51H1027.98V298.82H1036.81V244.51Z" fill="#F99344"/>
  <path d="M1028.79 244.51H1019.96V298.82H1028.79V244.51Z" fill="#DF843D"/>
  <path d="M1028.79 259.42C1041.92 259.42 1052.57 248.773 1052.57 235.64C1052.57 222.507 1041.92 211.86 1028.79 211.86C1015.66 211.86 1005.01 222.507 1005.01 235.64C1005.01 248.773 1015.66 259.42 1028.79 259.42Z" fill="#88F0AF"/>
  <path opacity="0.1" d="M1028.79 254.18C1022.94 254.181 1017.29 252.022 1012.93 248.118C1008.57 244.213 1005.8 238.838 1005.16 233.02C1005.05 233.906 1005 234.798 1005 235.69C1005 241.997 1007.51 248.045 1011.97 252.505C1016.42 256.965 1022.47 259.47 1028.78 259.47C1035.09 259.47 1041.14 256.965 1045.59 252.505C1050.05 248.045 1052.56 241.997 1052.56 235.69C1052.56 234.815 1052.51 233.94 1052.41 233.07C1051.76 238.877 1048.99 244.239 1044.63 248.133C1040.27 252.027 1034.63 254.18 1028.79 254.18V254.18Z" fill="black"/>
  <path d="M518.93 255.63H504.41V344.96H518.93V255.63Z" fill="#F99344"/>
  <path d="M505.74 255.63H491.22V344.96H505.74V255.63Z" fill="#DF843D"/>
  <path d="M505.74 280.16C527.34 280.16 544.85 262.65 544.85 241.05C544.85 219.45 527.34 201.94 505.74 201.94C484.14 201.94 466.63 219.45 466.63 241.05C466.63 262.65 484.14 280.16 505.74 280.16Z" fill="#68A81E"/>
  <path opacity="0.1" d="M505.74 271.54C496.114 271.54 486.826 267.989 479.654 261.568C472.483 255.148 467.931 246.307 466.87 236.74C466.709 238.171 466.629 239.61 466.63 241.05C466.63 251.423 470.751 261.37 478.085 268.705C485.42 276.039 495.367 280.16 505.74 280.16C516.113 280.16 526.06 276.039 533.395 268.705C540.73 261.37 544.85 251.423 544.85 241.05C544.846 239.61 544.766 238.171 544.61 236.74C543.547 246.307 538.995 255.145 531.823 261.566C524.652 267.986 515.365 271.537 505.74 271.54Z" fill="black"/>
  <path d="M597.32 332.31H588.49V359.05H597.32V332.31Z" fill="#F99344"/>
  <path d="M589.3 332.31H580.47V359.05H589.3V332.31Z" fill="#DF843D"/>
  <path d="M589.3 347.23C602.433 347.23 613.08 336.583 613.08 323.45C613.08 310.317 602.433 299.67 589.3 299.67C576.167 299.67 565.52 310.317 565.52 323.45C565.52 336.583 576.167 347.23 589.3 347.23Z" fill="#68A81E"/>
  <path opacity="0.1" d="M589.3 341.99C583.447 341.991 577.8 339.832 573.439 335.928C569.079 332.023 566.313 326.647 565.67 320.83C565.57 321.696 565.52 322.568 565.52 323.44C565.455 326.603 566.022 329.748 567.188 332.689C568.353 335.63 570.094 338.309 572.308 340.569C574.523 342.829 577.165 344.625 580.082 345.85C582.999 347.076 586.131 347.708 589.295 347.708C592.459 347.708 595.591 347.076 598.508 345.85C601.425 344.625 604.067 342.829 606.282 340.569C608.496 338.309 610.237 335.63 611.402 332.689C612.568 329.748 613.135 326.603 613.07 323.44C613.069 322.568 613.022 321.697 612.93 320.83C612.283 326.646 609.515 332.019 605.156 335.923C600.797 339.826 595.152 341.986 589.3 341.99Z" fill="black"/>
  <path d="M943.54 187.99C965.14 187.99 982.65 170.48 982.65 148.88C982.65 127.28 965.14 109.77 943.54 109.77C921.94 109.77 904.43 127.28 904.43 148.88C904.43 170.48 921.94 187.99 943.54 187.99Z" fill="#FAC038"/>
  <path opacity="0.1" d="M943.54 179.38C933.912 179.379 924.623 175.827 917.451 169.405C910.279 162.982 905.728 154.139 904.67 144.57C904.506 146.001 904.422 147.44 904.42 148.88C904.584 159.146 908.777 168.935 916.095 176.137C923.413 183.338 933.268 187.374 943.535 187.374C953.802 187.374 963.657 183.338 970.975 176.137C978.293 168.935 982.486 159.146 982.65 148.88C982.646 147.44 982.566 146.001 982.41 144.57C981.347 154.137 976.795 162.977 969.624 169.399C962.453 175.821 953.166 179.375 943.54 179.38V179.38Z" fill="black"/>
  <path d="M829.169 255.993L825.273 259.889L849.258 283.874L853.154 279.978L829.169 255.993Z" fill="#F99344"/>
  <path d="M825.629 259.53L821.733 263.426L845.718 287.411L849.614 283.515L825.629 259.53Z" fill="#DF843D"/>
  <path d="M820.93 270.23C832.13 270.23 841.21 261.15 841.21 249.95C841.21 238.75 832.13 229.67 820.93 229.67C809.73 229.67 800.65 238.75 800.65 249.95C800.65 261.15 809.73 270.23 820.93 270.23Z" fill="#68A81E"/>
  <path opacity="0.1" d="M820.93 265.76C815.945 265.762 811.133 263.928 807.415 260.607C803.696 257.287 801.33 252.714 800.77 247.76C800.69 248.504 800.65 249.252 800.65 250C800.65 255.378 802.787 260.537 806.59 264.34C810.393 268.143 815.551 270.28 820.93 270.28C826.309 270.28 831.467 268.143 835.27 264.34C839.073 260.537 841.21 255.378 841.21 250C841.207 249.251 841.164 248.504 841.08 247.76C840.52 252.712 838.156 257.284 834.439 260.604C830.722 263.924 825.914 265.76 820.93 265.76V265.76Z" fill="black"/>
  <path d="M920.521 201.265L918.117 203.67L932.889 218.441L935.293 216.037L920.521 201.265Z" fill="#F99344"/>
  <path d="M918.341 203.448L915.937 205.853L930.709 220.624L933.113 218.22L918.341 203.448Z" fill="#DF843D"/>
  <path d="M1224.3 240.271L1221.9 242.675L1236.67 257.446L1239.07 255.042L1224.3 240.271Z" fill="#F99344"/>
  <path d="M1222.12 242.454L1219.72 244.858L1234.49 259.629L1236.89 257.225L1222.12 242.454Z" fill="#DF843D"/>
  <path d="M1250.38 235.103L1253.78 238.498L1274.64 217.631L1271.25 214.237L1250.38 235.103Z" fill="#F99344"/>
  <path d="M1253.46 238.178L1256.86 241.572L1277.72 220.705L1274.33 217.311L1253.46 238.178Z" fill="#DF843D"/>
  <path d="M1282.61 225.36C1293.81 225.36 1302.89 216.28 1302.89 205.08C1302.89 193.88 1293.81 184.8 1282.61 184.8C1271.41 184.8 1262.33 193.88 1262.33 205.08C1262.33 216.28 1271.41 225.36 1282.61 225.36Z" fill="#68A81E"/>
  <path opacity="0.1" d="M1282.61 220.88C1287.6 220.884 1292.41 219.051 1296.13 215.73C1299.85 212.409 1302.21 207.834 1302.77 202.88C1302.85 203.62 1302.89 204.365 1302.89 205.11C1302.89 210.488 1300.75 215.647 1296.95 219.45C1293.15 223.253 1287.99 225.39 1282.61 225.39C1277.23 225.39 1272.07 223.253 1268.27 219.45C1264.47 215.647 1262.33 210.488 1262.33 205.11C1262.33 204.365 1262.38 203.62 1262.46 202.88C1263.02 207.833 1265.38 212.406 1269.1 215.727C1272.82 219.047 1277.63 220.882 1282.61 220.88V220.88Z" fill="black"/>
  <path d="M1217.27 249.93C1225.16 249.93 1231.56 243.532 1231.56 235.64C1231.56 227.748 1225.16 221.35 1217.27 221.35C1209.38 221.35 1202.98 227.748 1202.98 235.64C1202.98 243.532 1209.38 249.93 1217.27 249.93Z" fill="#68A81E"/>
  <path opacity="0.1" d="M1217.27 246.78C1220.79 246.782 1224.18 245.486 1226.8 243.14C1229.42 240.795 1231.08 237.565 1231.47 234.07C1231.53 234.591 1231.56 235.115 1231.56 235.64C1231.56 237.517 1231.19 239.375 1230.47 241.108C1229.75 242.842 1228.7 244.418 1227.37 245.744C1226.05 247.071 1224.47 248.124 1222.74 248.842C1221 249.56 1219.15 249.93 1217.27 249.93C1215.39 249.93 1213.54 249.56 1211.8 248.842C1210.07 248.124 1208.49 247.071 1207.17 245.744C1205.84 244.418 1204.79 242.842 1204.07 241.108C1203.35 239.375 1202.98 237.517 1202.98 235.64C1202.98 235.11 1202.98 234.58 1203.07 234.07C1203.46 237.564 1205.12 240.793 1207.74 243.138C1210.36 245.483 1213.75 246.779 1217.27 246.78V246.78Z" fill="black"/>
  <path d="M1557.33 196.604L1558.94 198.217L1568.87 188.282L1567.26 186.669L1557.33 196.604Z" fill="#F99344"/>
  <path d="M1558.79 198.072L1560.41 199.685L1570.34 189.75L1568.73 188.138L1558.79 198.072Z" fill="#DF843D"/>
  <path d="M1572.67 191.96C1578 191.96 1582.32 187.639 1582.32 182.31C1582.32 176.98 1578 172.66 1572.67 172.66C1567.34 172.66 1563.02 176.98 1563.02 182.31C1563.02 187.639 1567.34 191.96 1572.67 191.96Z" fill="#68A81E"/>
  <path opacity="0.1" d="M1572.67 189.84C1575.05 189.839 1577.34 188.963 1579.11 187.378C1580.88 185.793 1582 183.611 1582.26 181.25C1582.3 181.602 1582.32 181.956 1582.32 182.31C1582.24 184.817 1581.19 187.194 1579.38 188.939C1577.58 190.684 1575.17 191.659 1572.67 191.659C1570.16 191.659 1567.75 190.684 1565.95 188.939C1564.14 187.194 1563.09 184.817 1563.01 182.31C1563.01 181.956 1563.03 181.602 1563.07 181.25C1563.33 183.613 1564.46 185.796 1566.23 187.381C1568 188.966 1570.29 189.842 1572.67 189.84V189.84Z" fill="black"/>
  <path d="M1690.71 259.785L1692.33 261.398L1702.26 251.463L1700.65 249.851L1690.71 259.785Z" fill="#F99344"/>
  <path d="M1692.18 261.254L1693.79 262.866L1703.73 252.931L1702.12 251.319L1692.18 261.254Z" fill="#DF843D"/>
  <path d="M1555.53 163.516L1558.18 166.167L1574.48 149.868L1571.83 147.217L1555.53 163.516Z" fill="#F99344"/>
  <path d="M1557.93 165.917L1560.58 168.569L1576.88 152.27L1574.23 149.619L1557.93 165.917Z" fill="#DF843D"/>
  <path d="M1580.71 155.9C1589.46 155.9 1596.55 148.808 1596.55 140.06C1596.55 131.312 1589.46 124.22 1580.71 124.22C1571.96 124.22 1564.87 131.312 1564.87 140.06C1564.87 148.808 1571.96 155.9 1580.71 155.9Z" fill="#68A81E"/>
  <path opacity="0.1" d="M1580.71 152.41C1584.61 152.407 1588.37 150.969 1591.27 148.369C1594.17 145.77 1596.02 142.193 1596.45 138.32C1596.51 138.898 1596.54 139.479 1596.54 140.06C1596.59 142.173 1596.22 144.275 1595.45 146.243C1594.68 148.21 1593.52 150.004 1592.05 151.517C1590.57 153.03 1588.81 154.233 1586.86 155.054C1584.91 155.875 1582.82 156.298 1580.7 156.298C1578.59 156.298 1576.5 155.875 1574.55 155.054C1572.6 154.233 1570.84 153.03 1569.36 151.517C1567.89 150.004 1566.73 148.21 1565.96 146.243C1565.19 144.275 1564.82 142.173 1564.87 140.06C1564.87 139.479 1564.9 138.898 1564.96 138.32C1565.39 142.194 1567.24 145.773 1570.14 148.373C1573.05 150.972 1576.81 152.409 1580.71 152.41Z" fill="black"/>
  <path d="M614.955 230.485L611.808 233.632L631.147 252.971L634.294 249.824L614.955 230.485Z" fill="#F99344"/>
  <path d="M612.112 233.343L608.965 236.489L628.304 255.829L631.451 252.682L612.112 233.343Z" fill="#DF843D"/>
  <path d="M608.31 241.97C617.34 241.97 624.66 234.65 624.66 225.62C624.66 216.59 617.34 209.27 608.31 209.27C599.28 209.27 591.96 216.59 591.96 225.62C591.96 234.65 599.28 241.97 608.31 241.97Z" fill="#68A81E"/>
  <path opacity="0.1" d="M608.31 238.37C604.285 238.37 600.402 236.885 597.404 234.201C594.406 231.516 592.503 227.82 592.06 223.82C591.991 224.418 591.958 225.019 591.96 225.62C591.96 229.956 593.683 234.115 596.749 237.181C599.815 240.247 603.974 241.97 608.31 241.97C612.646 241.97 616.805 240.247 619.871 237.181C622.937 234.115 624.66 229.956 624.66 225.62C624.657 225.019 624.624 224.418 624.56 223.82C624.115 227.819 622.211 231.514 619.214 234.198C616.216 236.882 612.334 238.368 608.31 238.37V238.37Z" fill="black"/>
  <path d="M174.45 244.166L171.304 247.313L190.643 266.652L193.79 263.505L174.45 244.166Z" fill="#F99344"/>
  <path d="M171.583 247.024L168.437 250.17L187.776 269.51L190.923 266.363L171.583 247.024Z" fill="#DF843D"/>
  <path d="M165.67 257.4C174.7 257.4 182.02 250.08 182.02 241.05C182.02 232.02 174.7 224.7 165.67 224.7C156.64 224.7 149.32 232.02 149.32 241.05C149.32 250.08 156.64 257.4 165.67 257.4Z" fill="#68A81E"/>
  <path opacity="0.1" d="M165.67 253.8C161.646 253.798 157.764 252.312 154.766 249.628C151.769 246.944 149.865 243.249 149.42 239.25C149.356 239.848 149.323 240.449 149.32 241.05C149.32 245.386 151.043 249.545 154.109 252.611C157.175 255.677 161.334 257.4 165.67 257.4C170.006 257.4 174.165 255.677 177.231 252.611C180.297 249.545 182.02 245.386 182.02 241.05C182.022 240.449 181.989 239.848 181.92 239.25C181.477 243.25 179.574 246.946 176.576 249.631C173.578 252.315 169.694 253.8 165.67 253.8V253.8Z" fill="black"/>
  <path d="M860.04 257.09C881.64 257.09 899.15 239.58 899.15 217.98C899.15 196.38 881.64 178.87 860.04 178.87C838.44 178.87 820.93 196.38 820.93 217.98C820.93 239.58 838.44 257.09 860.04 257.09Z" fill="#68A81E"/>
  <path opacity="0.1" d="M860 248.47C850.383 248.462 841.107 244.911 833.943 238.495C826.78 232.079 822.232 223.248 821.17 213.69C821.009 215.121 820.929 216.56 820.93 218C820.93 228.373 825.051 238.32 832.385 245.655C839.72 252.989 849.667 257.11 860.04 257.11C870.413 257.11 880.36 252.989 887.695 245.655C895.03 238.32 899.15 228.373 899.15 218C899.146 216.56 899.066 215.121 898.91 213.69C897.842 223.26 893.281 232.099 886.102 238.517C878.923 244.934 869.629 248.478 860 248.47V248.47Z" fill="black"/>
  <path d="M1547.27 152.02C1568.87 152.02 1586.38 134.51 1586.38 112.91C1586.38 91.3101 1568.87 73.7999 1547.27 73.7999C1525.67 73.7999 1508.16 91.3101 1508.16 112.91C1508.16 134.51 1525.67 152.02 1547.27 152.02Z" fill="#68A81E"/>
  <path opacity="0.1" d="M1547.27 143.4C1537.64 143.4 1528.36 139.849 1521.18 133.428C1514.01 127.008 1509.46 118.167 1508.4 108.6C1508.24 110.031 1508.16 111.47 1508.16 112.91C1508.16 123.283 1512.28 133.23 1519.62 140.565C1526.95 147.899 1536.9 152.02 1547.27 152.02C1557.64 152.02 1567.59 147.899 1574.92 140.565C1582.26 133.23 1586.38 123.283 1586.38 112.91C1586.38 111.47 1586.3 110.031 1586.14 108.6C1585.08 118.167 1580.52 127.005 1573.35 133.426C1566.18 139.846 1556.9 143.397 1547.27 143.4Z" fill="black"/>
  <path d="M917.38 211.87C922.527 211.87 926.7 207.697 926.7 202.55C926.7 197.403 922.527 193.23 917.38 193.23C912.233 193.23 908.06 197.403 908.06 202.55C908.06 207.697 912.233 211.87 917.38 211.87Z" fill="#FAC038"/>
  <path opacity="0.1" d="M917.38 209.81C915.086 209.81 912.872 208.964 911.162 207.435C909.452 205.905 908.366 203.8 908.11 201.52C908.11 201.85 908.06 202.2 908.06 202.52C908.06 204.992 909.042 207.362 910.79 209.11C912.538 210.858 914.908 211.84 917.38 211.84C919.852 211.84 922.222 210.858 923.97 209.11C925.718 207.362 926.7 204.992 926.7 202.52C926.699 202.186 926.679 201.852 926.64 201.52C926.387 203.799 925.302 205.904 923.594 207.434C921.885 208.963 919.673 209.809 917.38 209.81V209.81Z" fill="black"/>
  <path d="M754.468 129.364L750.692 133.14L773.913 156.361L777.689 152.585L754.468 129.364Z" fill="#D7781B"/>
  <path d="M751.045 132.788L747.269 136.563L770.49 159.785L774.266 156.009L751.045 132.788Z" fill="#C26C17"/>
  <path d="M513.812 290.738L515.927 292.852L528.937 279.841L526.823 277.727L513.812 290.738Z" fill="#F99344"/>
  <path d="M515.732 292.653L517.846 294.767L530.857 281.756L528.743 279.642L515.732 292.653Z" fill="#DF843D"/>
  <path d="M533.91 284.65C540.891 284.65 546.55 278.991 546.55 272.01C546.55 265.029 540.891 259.37 533.91 259.37C526.929 259.37 521.27 265.029 521.27 272.01C521.27 278.991 526.929 284.65 533.91 284.65Z" fill="#68A81E"/>
  <path opacity="0.1" d="M533.91 281.87C537.022 281.867 540.023 280.719 542.342 278.644C544.66 276.568 546.133 273.712 546.48 270.62C546.666 272.382 546.48 274.164 545.933 275.849C545.386 277.535 544.491 279.086 543.306 280.404C542.12 281.721 540.671 282.774 539.053 283.495C537.434 284.216 535.682 284.588 533.91 284.588C532.138 284.588 530.386 284.216 528.767 283.495C527.149 282.774 525.7 281.721 524.514 280.404C523.329 279.086 522.434 277.535 521.887 275.849C521.34 274.164 521.154 272.382 521.34 270.62C521.687 273.712 523.16 276.568 525.478 278.644C527.797 280.719 530.798 281.867 533.91 281.87Z" fill="black"/>
  <path d="M423.485 286.45L426.313 289.278L443.722 271.869L440.894 269.041L423.485 286.45Z" fill="#F99344"/>
  <path d="M426.062 289.032L428.89 291.86L446.299 274.452L443.471 271.623L426.062 289.032Z" fill="#DF843D"/>
  <path d="M446.29 275.86C454.182 275.86 460.58 269.462 460.58 261.57C460.58 253.678 454.182 247.28 446.29 247.28C438.398 247.28 432 253.678 432 261.57C432 269.462 438.398 275.86 446.29 275.86Z" fill="#FAC038"/>
  <path opacity="0.1" d="M446.29 272.69C442.773 272.692 439.38 271.396 436.76 269.05C434.139 266.705 432.477 263.475 432.09 259.98C432.03 260.501 431.999 261.025 432 261.55C432 263.427 432.37 265.285 433.088 267.019C433.806 268.752 434.859 270.328 436.185 271.655C437.512 272.981 439.088 274.034 440.821 274.752C442.555 275.47 444.413 275.84 446.29 275.84C448.167 275.84 450.025 275.47 451.759 274.752C453.492 274.034 455.068 272.981 456.395 271.655C457.722 270.328 458.774 268.752 459.492 267.019C460.21 265.285 460.58 263.427 460.58 261.55C460.58 261.02 460.58 260.49 460.5 259.98C460.109 263.475 458.444 266.704 455.822 269.048C453.201 271.393 449.807 272.69 446.29 272.69V272.69Z" fill="black"/>
  <path d="M1705.05 255.04C1708.97 255.04 1712.15 251.861 1712.15 247.94C1712.15 244.019 1708.97 240.84 1705.05 240.84C1701.13 240.84 1697.95 244.019 1697.95 247.94C1697.95 251.861 1701.13 255.04 1705.05 255.04Z" fill="#FACEE7"/>
  <path opacity="0.1" d="M1705.05 253.47C1703.31 253.456 1701.64 252.805 1700.35 251.64C1699.06 250.475 1698.24 248.878 1698.05 247.15C1698.04 247.41 1698.04 247.67 1698.05 247.93C1698.12 249.768 1698.9 251.508 1700.22 252.784C1701.55 254.06 1703.32 254.773 1705.15 254.773C1706.99 254.773 1708.76 254.06 1710.09 252.784C1711.41 251.508 1712.19 249.768 1712.26 247.93C1712.26 247.669 1712.24 247.409 1712.21 247.15C1712.01 248.904 1711.17 250.523 1709.85 251.691C1708.53 252.859 1706.82 253.493 1705.05 253.47V253.47Z" fill="black"/>
  <path d="M1689.45 276.984L1691.06 278.596L1701 268.662L1699.39 267.049L1689.45 276.984Z" fill="#F99344"/>
  <path d="M1690.91 278.452L1692.53 280.064L1702.46 270.13L1700.85 268.517L1690.91 278.452Z" fill="#DF843D"/>
  <path d="M1703.79 272.24C1707.71 272.24 1710.89 269.061 1710.89 265.14C1710.89 261.219 1707.71 258.04 1703.79 258.04C1699.87 258.04 1696.69 261.219 1696.69 265.14C1696.69 269.061 1699.87 272.24 1703.79 272.24Z" fill="#FACEE7"/>
  <path opacity="0.1" d="M1703.79 270.69C1702.04 270.691 1700.35 270.046 1699.05 268.88C1697.75 267.714 1696.92 266.108 1696.73 264.37C1696.71 264.633 1696.71 264.897 1696.73 265.16C1696.73 267.043 1697.48 268.849 1698.81 270.18C1700.14 271.512 1701.95 272.26 1703.83 272.26C1705.71 272.26 1707.52 271.512 1708.85 270.18C1710.18 268.849 1710.93 267.043 1710.93 265.16C1710.93 264.89 1710.93 264.63 1710.93 264.37C1710.74 266.122 1709.9 267.739 1708.58 268.907C1707.26 270.075 1705.55 270.71 1703.79 270.69Z" fill="black"/>
  <path d="M760 344.99C760 340.216 761.896 335.638 765.272 332.262C768.648 328.886 773.226 326.99 778 326.99C782.774 326.99 787.352 328.886 790.728 332.262C794.104 335.638 796 340.216 796 344.99H760Z" fill="#D7781B"/>
  <path d="M253.774 262.423L249.998 266.199L273.219 289.42L276.995 285.644L253.774 262.423Z" fill="#D7781B"/>
  <path d="M250.361 265.856L246.585 269.632L269.806 292.854L273.582 289.078L250.361 265.856Z" fill="#C26C17"/>
  <path d="M292.198 260.403L294.496 262.701L308.631 248.566L306.333 246.268L292.198 260.403Z" fill="#D7781B"/>
  <path d="M294.278 262.487L296.576 264.785L310.711 250.65L308.413 248.352L294.278 262.487Z" fill="#C26C17"/>
  <path d="M1074.8 279.338L1072 282.131L1089.2 299.328L1091.99 296.535L1074.8 279.338Z" fill="#D7781B"/>
  <path d="M1072.26 281.881L1069.46 284.674L1086.66 301.87L1089.45 299.077L1072.26 281.881Z" fill="#C26C17"/>
  <path d="M1107.08 312.184L1109.69 314.794L1125.74 298.742L1123.13 296.133L1107.08 312.184Z" fill="#D7781B"/>
  <path d="M1109.45 314.553L1112.06 317.162L1128.11 301.111L1125.5 298.501L1109.45 314.553Z" fill="#C26C17"/>
  <path d="M1032.39 273.058L1034.79 275.463L1049.57 260.691L1047.16 258.287L1032.39 273.058Z" fill="#F99344"/>
  <path d="M1034.57 275.235L1036.97 277.64L1051.75 262.868L1049.34 260.464L1034.57 275.235Z" fill="#DF843D"/>
  <path d="M1052.57 264.25C1058.02 264.25 1062.44 259.831 1062.44 254.38C1062.44 248.929 1058.02 244.51 1052.57 244.51C1047.12 244.51 1042.7 248.929 1042.7 254.38C1042.7 259.831 1047.12 264.25 1052.57 264.25Z" fill="#88F0AF"/>
  <path opacity="0.1" d="M1052.57 262.07C1050.14 262.068 1047.8 261.17 1045.99 259.548C1044.18 257.927 1043.03 255.695 1042.76 253.28C1042.61 254.614 1042.74 255.963 1043.13 257.247C1043.52 258.53 1044.17 259.72 1045.04 260.745C1045.9 261.769 1046.97 262.605 1048.17 263.203C1049.37 263.801 1050.68 264.147 1052.02 264.222C1053.36 264.296 1054.7 264.096 1055.96 263.634C1057.22 263.173 1058.38 262.459 1059.35 261.537C1060.33 260.615 1061.1 259.504 1061.63 258.272C1062.17 257.04 1062.44 255.712 1062.44 254.37C1062.44 254 1062.44 253.64 1062.38 253.28C1062.11 255.694 1060.96 257.925 1059.15 259.546C1057.34 261.167 1055 262.065 1052.57 262.07V262.07Z" fill="black"/>
  <path d="M252.46 181.13H243.63V235.44H252.46V181.13Z" fill="#F99344"/>
  <path d="M244.44 181.13H235.61V235.44H244.44V181.13Z" fill="#DF843D"/>
  <path d="M244.44 196.05C257.573 196.05 268.22 185.403 268.22 172.27C268.22 159.137 257.573 148.49 244.44 148.49C231.307 148.49 220.66 159.137 220.66 172.27C220.66 185.403 231.307 196.05 244.44 196.05Z" fill="#88F0AF"/>
  <path opacity="0.1" d="M244.44 190.81C238.593 190.812 232.951 188.659 228.591 184.763C224.232 180.866 221.462 175.5 220.81 169.69C220.709 170.56 220.659 171.434 220.66 172.31C220.66 178.617 223.165 184.665 227.625 189.125C232.085 193.585 238.133 196.09 244.44 196.09C250.747 196.09 256.795 193.585 261.255 189.125C265.715 184.665 268.22 178.617 268.22 172.31C268.216 171.435 268.166 170.56 268.07 169.69C267.418 175.5 264.648 180.866 260.288 184.763C255.929 188.659 250.287 190.812 244.44 190.81V190.81Z" fill="black"/>
  <path d="M225.627 193.774L223.222 196.178L237.994 210.949L240.398 208.545L225.627 193.774Z" fill="#F99344"/>
  <path d="M223.437 195.947L221.032 198.351L235.804 213.122L238.208 210.718L223.437 195.947Z" fill="#DF843D"/>
  <path d="M219.75 200.18C225.201 200.18 229.62 195.761 229.62 190.31C229.62 184.859 225.201 180.44 219.75 180.44C214.299 180.44 209.88 184.859 209.88 190.31C209.88 195.761 214.299 200.18 219.75 200.18Z" fill="#88F0AF"/>
  <path opacity="0.1" d="M219.75 198C217.319 198 214.974 197.103 213.164 195.481C211.354 193.859 210.206 191.626 209.94 189.21C209.768 190.599 209.893 192.009 210.307 193.347C210.721 194.684 211.415 195.918 212.342 196.968C213.269 198.017 214.408 198.857 215.685 199.432C216.961 200.007 218.345 200.305 219.745 200.305C221.145 200.305 222.529 200.007 223.805 199.432C225.082 198.857 226.221 198.017 227.148 196.968C228.075 195.918 228.769 194.684 229.183 193.347C229.597 192.009 229.722 190.599 229.55 189.21C229.284 191.624 228.138 193.856 226.33 195.477C224.521 197.099 222.179 197.997 219.75 198V198Z" fill="black"/>
  <path d="M130.169 185.174L133.945 188.95L157.167 165.729L153.391 161.953L130.169 185.174Z" fill="#D7781B"/>
  <path d="M133.589 188.608L137.365 192.384L160.587 169.163L156.811 165.387L133.589 188.608Z" fill="#C26C17"/>
  <path d="M162.38 177.16C172.012 177.16 179.82 169.352 179.82 159.72C179.82 150.088 172.012 142.28 162.38 142.28C152.748 142.28 144.94 150.088 144.94 159.72C144.94 169.352 152.748 177.16 162.38 177.16Z" fill="#68A81E"/>
  <path opacity="0.1" d="M162.38 173.32C158.079 173.331 153.926 171.753 150.718 168.888C147.51 166.024 145.473 162.075 145 157.8C144.718 160.244 144.956 162.72 145.699 165.066C146.441 167.412 147.671 169.574 149.308 171.41C150.945 173.247 152.952 174.717 155.197 175.723C157.442 176.73 159.875 177.25 162.335 177.25C164.795 177.25 167.228 176.73 169.473 175.723C171.718 174.717 173.725 173.247 175.362 171.41C176.999 169.574 178.229 167.412 178.972 165.066C179.714 162.72 179.952 160.244 179.67 157.8C179.2 162.06 177.178 165.998 173.988 168.861C170.799 171.724 166.666 173.311 162.38 173.32Z" fill="black"/>
  <path d="M1471.14 247.62H1462.31V301.93H1471.14V247.62Z" fill="#F99344"/>
  <path d="M1463.12 247.62H1454.29V301.93H1463.12V247.62Z" fill="#DF843D"/>
  <path d="M1463.12 262.54C1476.25 262.54 1486.9 251.893 1486.9 238.76C1486.9 225.627 1476.25 214.98 1463.12 214.98C1449.99 214.98 1439.34 225.627 1439.34 238.76C1439.34 251.893 1449.99 262.54 1463.12 262.54Z" fill="#88F0AF"/>
  <path opacity="0.1" d="M1463.12 257.29C1457.27 257.291 1451.62 255.132 1447.26 251.228C1442.9 247.323 1440.13 241.948 1439.49 236.13C1439.39 237 1439.34 237.875 1439.34 238.75C1439.28 241.913 1439.84 245.058 1441.01 247.999C1442.17 250.94 1443.91 253.619 1446.13 255.879C1448.34 258.139 1450.99 259.935 1453.9 261.161C1456.82 262.386 1459.95 263.018 1463.11 263.018C1466.28 263.018 1469.41 262.386 1472.33 261.161C1475.24 259.935 1477.89 258.139 1480.1 255.879C1482.32 253.619 1484.06 250.94 1485.22 247.999C1486.39 245.058 1486.95 241.913 1486.89 238.75C1486.89 237.875 1486.84 237 1486.75 236.13C1486.1 241.946 1483.34 247.319 1478.98 251.223C1474.62 255.126 1468.97 257.287 1463.12 257.29V257.29Z" fill="black"/>
  <path d="M1448.05 264.885L1446.47 266.469L1456.23 276.227L1457.81 274.643L1448.05 264.885Z" fill="#F99344"/>
  <path d="M1446.61 266.321L1445.03 267.905L1454.78 277.663L1456.37 276.079L1446.61 266.321Z" fill="#DF843D"/>
  <path d="M1443.23 269.02C1447.3 269.02 1450.6 265.72 1450.6 261.65C1450.6 257.58 1447.3 254.28 1443.23 254.28C1439.16 254.28 1435.86 257.58 1435.86 261.65C1435.86 265.72 1439.16 269.02 1443.23 269.02Z" fill="#88F0AF"/>
  <path opacity="0.1" d="M1443.23 267.39C1441.42 267.389 1439.67 266.72 1438.32 265.512C1436.97 264.304 1436.11 262.641 1435.91 260.84C1435.89 261.11 1435.89 261.38 1435.91 261.65C1435.98 263.556 1436.79 265.361 1438.16 266.685C1439.53 268.009 1441.37 268.748 1443.28 268.748C1445.18 268.748 1447.02 268.009 1448.39 266.685C1449.76 265.361 1450.57 263.556 1450.64 261.65C1450.66 261.38 1450.66 261.11 1450.64 260.84C1450.44 262.657 1449.57 264.334 1448.2 265.545C1446.83 266.756 1445.06 267.413 1443.23 267.39V267.39Z" fill="black"/>
  <path d="M658.05 328.5H652.12V349.68H658.05V328.5Z" fill="#F99344"/>
  <path d="M652.66 328.5H646.73V349.68H652.66V328.5Z" fill="#DF843D"/>
  <path d="M652.67 335.37C660.562 335.37 666.96 328.972 666.96 321.08C666.96 313.188 660.562 306.79 652.67 306.79C644.778 306.79 638.38 313.188 638.38 321.08C638.38 328.972 644.778 335.37 652.67 335.37Z" fill="#FAC038"/>
  <path opacity="0.1" d="M652.67 332.21C649.153 332.21 645.759 330.913 643.138 328.568C640.516 326.224 638.851 322.995 638.46 319.5C638.41 320.01 638.38 320.5 638.38 321.07C638.38 324.86 639.886 328.495 642.565 331.174C645.245 333.854 648.88 335.36 652.67 335.36C656.46 335.36 660.095 333.854 662.775 331.174C665.454 328.495 666.96 324.86 666.96 321.07C666.961 320.545 666.93 320.021 666.87 319.5C666.483 322.995 664.821 326.225 662.2 328.57C659.58 330.915 656.187 332.211 652.67 332.21Z" fill="black"/>
  <path d="M1761.53 328.96H1755.6V350.14H1761.53V328.96Z" fill="#F99344"/>
  <path d="M1756.14 328.96H1750.21V350.14H1756.14V328.96Z" fill="#DF843D"/>
  <path d="M1756.14 335.82C1764.03 335.82 1770.43 329.422 1770.43 321.53C1770.43 313.638 1764.03 307.24 1756.14 307.24C1748.25 307.24 1741.85 313.638 1741.85 321.53C1741.85 329.422 1748.25 335.82 1756.14 335.82Z" fill="#FAC038"/>
  <path opacity="0.1" d="M1756.14 332.69C1752.62 332.689 1749.23 331.391 1746.61 329.044C1743.99 326.697 1742.33 323.466 1741.94 319.97C1741.94 320.49 1741.85 321.02 1741.85 321.55C1741.95 325.276 1743.5 328.816 1746.17 331.416C1748.84 334.017 1752.42 335.472 1756.15 335.472C1759.87 335.472 1763.45 334.017 1766.12 331.416C1768.79 328.816 1770.34 325.276 1770.44 321.55C1770.44 321.02 1770.44 320.49 1770.35 319.97C1769.96 323.468 1768.3 326.7 1765.68 329.047C1763.06 331.394 1759.66 332.692 1756.14 332.69V332.69Z" fill="black"/>
  <path d="M1140.31 199.3H1134.38V220.48H1140.31V199.3Z" fill="#F99344"/>
  <path d="M1134.92 199.3H1128.99V220.48H1134.92V199.3Z" fill="#DF843D"/>
  <path d="M1134.38 212.71C1139.83 212.71 1144.25 208.291 1144.25 202.84C1144.25 197.389 1139.83 192.97 1134.38 192.97C1128.93 192.97 1124.51 197.389 1124.51 202.84C1124.51 208.291 1128.93 212.71 1134.38 212.71Z" fill="#88F0AF"/>
  <path opacity="0.1" d="M1134.38 210.53C1131.95 210.528 1129.61 209.632 1127.8 208.012C1125.99 206.392 1124.84 204.163 1124.57 201.75C1124.57 202.11 1124.51 202.47 1124.51 202.84C1124.51 204.182 1124.78 205.509 1125.32 206.742C1125.85 207.974 1126.62 209.085 1127.6 210.007C1128.57 210.929 1129.73 211.643 1130.99 212.104C1132.25 212.566 1133.59 212.766 1134.93 212.692C1136.27 212.617 1137.58 212.271 1138.78 211.673C1139.98 211.075 1141.05 210.239 1141.91 209.214C1142.78 208.19 1143.43 207 1143.82 205.717C1144.21 204.433 1144.34 203.084 1144.19 201.75C1143.92 204.164 1142.77 206.395 1140.96 208.015C1139.15 209.634 1136.81 210.53 1134.38 210.53Z" fill="black"/>
  <path d="M1.87999 282.53C1.27999 282.53 0.699985 282.53 0.109985 282.61V330.02C0.699985 330.02 1.27999 330.09 1.87999 330.09C8.18683 330.09 14.2354 327.585 18.695 323.125C23.1546 318.665 25.66 312.617 25.66 306.31C25.66 300.003 23.1546 293.955 18.695 289.495C14.2354 285.035 8.18683 282.53 1.87999 282.53V282.53Z" fill="#68A81E"/>
  <path opacity="0.1" d="M25.51 303.69C24.8631 309.506 22.0954 314.879 17.7361 318.783C13.3768 322.686 7.73166 324.846 1.87999 324.85C1.27999 324.85 0.699985 324.85 0.109985 324.77V330.02C0.699985 330.02 1.27999 330.09 1.87999 330.09C8.1851 330.087 14.2311 327.581 18.6885 323.121C23.146 318.662 25.65 312.615 25.65 306.31C25.6488 305.435 25.602 304.56 25.51 303.69V303.69Z" fill="black"/>
    </svg>
  )
}

export default Mural;