import React from 'react';
import { useSelector } from 'react-redux';
import { selectSandboxMode } from '../../redux/gameSlice';

const StarIcon = ({amount, color}) => {

    let isSandbox = useSelector(selectSandboxMode);

    return (
        <div className={`flex w-max items-start ${amount === 0 && !isSandbox ? 'opacity-50' : 'opacity-100'}`}>
            <svg height="24" className={`${color} fill-current`} viewBox="0 0 31 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.4718 13.4494L13.0051 23.1628C12.9795 23.2654 12.9776 23.3726 12.9995 23.4761C13.0215 23.5796 13.0667 23.6768 13.1317 23.7602C13.1968 23.8436 13.28 23.9112 13.375 23.9577C13.47 24.0042 13.5744 24.0285 13.6802 24.0287H18.7433C18.8406 24.0287 18.9368 24.0082 19.0256 23.9687C19.1145 23.9292 19.1941 23.8715 19.2593 23.7993C19.3245 23.7271 19.3739 23.6421 19.4042 23.5497C19.4345 23.4573 19.4451 23.3595 19.4353 23.2628L18.6446 15.5824C18.6272 15.4095 18.6749 15.2364 18.7784 15.0969C18.8819 14.9574 19.0337 14.8615 19.2042 14.8281L24.253 13.8531C24.3921 13.8259 24.5196 13.7571 24.6186 13.6558C24.7176 13.5545 24.7834 13.4254 24.8074 13.2858L25.215 10.8685C25.2358 10.7495 25.2253 10.6272 25.1845 10.5136C25.1438 10.3999 25.0741 10.2988 24.9824 10.2203C24.8908 10.1417 24.7802 10.0883 24.6617 10.0654C24.5431 10.0425 24.4206 10.0508 24.3063 10.0895L15.9209 12.9625C15.8117 13.0003 15.7135 13.0645 15.6351 13.1494C15.5567 13.2344 15.5006 13.3374 15.4718 13.4494V13.4494Z" fill="white"/>
                <path d="M4.52761 5.83006L14.8785 11.8019C14.9647 11.8515 15.0605 11.882 15.1595 11.8914C15.2584 11.9008 15.3583 11.8889 15.4523 11.8565L24.3608 8.78614C24.472 8.74726 24.5714 8.68073 24.6499 8.59283C24.7283 8.50492 24.783 8.39851 24.809 8.28362C24.8349 8.16873 24.8313 8.04911 24.7983 7.93603C24.7653 7.82295 24.7041 7.72012 24.6204 7.6372L17.6216 0.653986C17.4986 0.530603 17.3335 0.458261 17.1595 0.451461L7.27468 0.0347269C7.14237 0.0284118 7.01099 0.0599965 6.896 0.125768C6.78102 0.191541 6.6872 0.288767 6.62556 0.406023L4.25108 4.90831C4.16923 5.06792 4.15231 5.25302 4.20386 5.42483C4.2554 5.59665 4.37141 5.74186 4.52761 5.83006V5.83006Z" fill="white"/>
                <path d="M4.38095 7.15559L13.8581 12.5017C13.9918 12.5766 14.0969 12.6936 14.157 12.8346C14.2172 12.9755 14.2289 13.1324 14.1904 13.2807L11.6394 23.5069C11.602 23.6575 11.5151 23.7913 11.3928 23.8869C11.2704 23.9824 11.1196 24.0342 10.9643 24.034H4.99243C4.87747 24.0341 4.76428 24.0057 4.66299 23.9513C4.56171 23.897 4.47551 23.8183 4.41212 23.7224L0.111064 17.2091C0.0510054 17.1179 0.0132483 17.0139 0.000844048 16.9054C-0.0115602 16.7969 0.00173035 16.6871 0.0396549 16.5847L3.38781 7.52558C3.42205 7.43071 3.47653 7.34443 3.54748 7.27274C3.61843 7.20106 3.70415 7.14568 3.79866 7.11047C3.89318 7.07526 3.99422 7.06106 4.09478 7.06885C4.19533 7.07664 4.29299 7.10624 4.38095 7.15559Z" fill="white"/>
                <path d="M20.4323 15.8434L25.7317 14.8152C25.8421 14.7938 25.9559 14.7995 26.0636 14.8319C26.1712 14.8642 26.2694 14.9222 26.3497 15.0008L29.9718 18.5359C30.0852 18.6462 30.1575 18.7918 30.1769 18.9488C30.1962 19.1058 30.1614 19.2647 30.0782 19.3992L27.4155 23.7003C27.353 23.8006 27.266 23.8834 27.1627 23.9409C27.0593 23.9983 26.9431 24.0286 26.8248 24.0287H21.345C21.1743 24.0286 21.0096 23.9657 20.8822 23.8519C20.7549 23.7382 20.6738 23.5817 20.6543 23.4121L19.8754 16.6054C19.8555 16.4316 19.9017 16.2567 20.0049 16.1155C20.1081 15.9743 20.2607 15.8771 20.4323 15.8434V15.8434Z" fill="white"/>
            </svg>
            {isSandbox ? 
                <svg width="26" height="14" viewBox="0 0 26 14" className="fill-current text-white my-auto ml-1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.1358 0C15.8882 0 13.3774 2.28539 12.4561 5.39365C12.3253 5.76812 12.202 6.16186 12.0904 6.57169C11.9832 6.94937 11.9081 7.18563 11.8008 7.626C11.3064 9.62209 9.3941 11.1071 6.86724 11.1071C4.0433 11.1071 1.93366 8.93905 1.93366 7.00081C1.93366 5.06257 4.0433 2.89289 6.86724 2.89289C8.41596 2.89289 9.75017 3.5454 10.6393 4.45505C10.6832 4.49914 10.7331 4.52809 10.7853 4.5398C10.8376 4.55152 10.8909 4.54572 10.9415 4.52281C10.9921 4.49991 11.0386 4.46046 11.0778 4.40734C11.117 4.35421 11.1478 4.28872 11.168 4.21558C11.3075 3.71576 11.507 3.03271 11.6796 2.54735C11.7187 2.43952 11.7293 2.31369 11.7095 2.19466C11.6896 2.07564 11.6407 1.97209 11.5724 1.90448C10.3443 0.724826 8.6916 0.00160873 6.87153 0.00160873C3.08018 0.00160873 0.00741577 3.13558 0.00741577 7.00242C0.00741577 10.8692 3.08018 14.0016 6.87153 14.0016C9.95502 14.0016 12.5634 11.9284 13.43 9.07244C13.5149 8.81973 13.5866 8.55751 13.6445 8.28815C14.2805 5.33579 15.5943 4.15131 16.4223 3.62738C17.29 3.12969 18.2121 2.87895 19.1411 2.88807C21.9651 2.88807 24.0747 5.05614 24.0747 6.99598C24.0747 8.93583 21.9651 11.1023 19.1411 11.1023C17.601 11.1023 16.2732 10.4594 15.3873 9.5562C15.3406 9.50976 15.2878 9.47884 15.2325 9.46554C15.1771 9.45225 15.1205 9.45687 15.0664 9.4791C15.0124 9.50133 14.9621 9.54067 14.9191 9.59444C14.876 9.64821 14.8411 9.71518 14.8167 9.79084C14.6645 10.2569 14.4639 10.8596 14.3041 11.2919C14.2544 11.4238 14.24 11.5791 14.2639 11.7262C14.2878 11.8734 14.3482 12.0014 14.4328 12.0843C15.663 13.2687 17.32 13.9952 19.1444 13.9952C22.9357 13.9952 26.0085 10.8612 26.0085 6.99598C26.0085 3.13076 22.9271 0 19.1358 0Z" />
                </svg>
            :
                <div className={`ml-1 ${color} text-md font-medium no-select`}>{`: ${amount}`}</div>
            }
        </div>
    )
}

export default StarIcon;
